import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Icon = styled.svg`
  fill: ${props => props.theme[props.color]};
  ${props => props.styles}
  max-width: 450px;
`

const Blobs = props => {
  const { smoothBlob, fourLumps, color, style } = props
  return (
    <>
      {smoothBlob && (
        <Icon
          color={color}
          styles={style}
          data-name='smooth-blob'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 346 297'
        >
          <title>Smooth Blob</title>
          <path d='M346.22,156.51c1.18,23.48-7,44.3-18.81,64-6.37,10.64-12.17,21.87-20.07,31.29-20.3,24.2-45.95,40.18-77.72,44.49-27.41,3.72-54.26-1.57-80.87-7.21-31.1-6.6-61.22-16.41-89.17-31.87-18.36-10.16-36.3-21.11-48.44-39C4.27,208.05,1.29,196.54.67,184.38c-1.49-29,.66-57.75,9.08-85.67C17.76,72.13,29.9,47.55,50.13,28,77.37,1.75,109.39-5.3,145.8,5.35c12,3.5,24.56,4.92,36.86,7.31C209.92,18,236.85,24,260.36,40.11c13.41,9.16,28.09,16.66,40.65,26.83,23.92,19.36,40.46,43.88,45.12,75.09C346.83,146.76,346.22,151.68,346.22,156.51Z' />
        </Icon>
      )}
      {fourLumps && (
        <Icon
          color={color}
          styles={style}
          data-name='four-lump-blob'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 574 480'
        >
          <path d='M327.21.23C371.8.58,405.7,16.35,431.73,46.68c12.6,14.68,22,32.32,31.6,49.3C476,118.27,489.17,140,508.39,157.33c10.22,9.19,21.25,17.48,31.91,26.17,15.37,12.53,28.34,26.72,32.08,47.13,2.91,15.86.56,31.21-5,46.18-8.76,23.67-20.73,45.42-38.57,63.54-19.34,19.65-42.54,32.54-69.2,39.58C438.3,385.55,417,391.24,395.9,397.8c-18,5.61-32.4,16.91-44.49,31.46C329,456.17,300.94,473.6,266,479c-19.16,2.95-37.3-.65-54.86-8.49-23.42-10.45-43.32-26.14-63.07-42-20-16.09-36-36-53.15-54.94-15.32-16.87-31.78-32.72-48.15-48.59-11.07-10.73-19.54-23.12-26.9-36.53C9,268.66,4.24,247.36,1.05,225.28c-3.66-25.39,3.11-47.55,17.46-68,8.31-11.83,20.27-18.94,33.37-24.08,15.55-6.1,31.86-10.5,46.88-17.65,14.31-6.81,27.58-15.92,40.9-24.63,4.29-2.81,7.38-7.5,10.91-11.42a220.15,220.15,0,0,1,71.58-52.93A280.91,280.91,0,0,1,302.09,2.9C311.74,1.57,321.48.83,327.21.23Z' />
        </Icon>
      )}
    </>
  )
}
Blobs.propTypes = {
  smoothBlob: PropTypes.bool,
  fourLumps: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.string,
}
export default Blobs
