import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg`
  position: absolute;
  z-index: 1;
  max-width: 450px;
  fill: ${props => props.theme.darkGrey};
`

const Self = () => {
  return (
    <Icon
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1475 1494'
    >
      <defs></defs>
      <g id='gxWANG.tif_1_'>
        <g>
          <g>
            <path
              d='M0,1324c2.2-3.8,3.9-8,6.6-11.4c4.8-5.9,10-11.6,15.3-17c13.2-13.5,29-23.7,44.5-34.1c20.2-13.5,40.4-27,60.9-40
				c22.7-14.4,47.5-24.4,73-32.7c11.1-3.6,22.5-3.3,33.8-1.1c5.2,1,10.3,2.1,15.6,2.5c7.4,0.6,13.9-2.3,20.1-5.9
				c1.1-0.6,1.8-2.2,2.3-3.4c3.3-8.6,6.3-17.3,9.9-25.8c3.3-7.7,9.3-13.4,15.6-18.5c11.2-9.3,21.6-19.2,31.1-30.2
				c9.7-11.3,21.5-20.1,34.1-28c14.5-9.1,30.2-14.5,47.1-17.2c4.5-0.7,8.8-3.7,13-5.9c12.8-6.5,23.7-15.8,34.9-24.5
				c3.7-2.9,6.4-7.1,9.2-11c1.6-2.2,3.2-2.7,6-1.6c1.4-4.2,2.7-8.4,4.3-12.4c1.3-3.2,2.5-6.5,4.4-9.3c5.6-8.4,8.2-17.9,10.5-27.5
				c1.7-7,1-14.1,0.9-21.1c-0.4-0.1-0.7-0.2-1.1-0.3c-0.5,0.9-1,1.7-1.4,2.6c-3.4,9.6-6.3,19.4-10.3,28.8c-4,9.4-8,18.9-14.8,26.9
				c-3,3.5-6.4,5.2-11.2,5.2c-8.5-0.1-16.5,3-24.5,5.4c-5.5,1.7-10.9,3.9-16.5,5.2c-5.1,1.2-10.4,1.5-15.6,2.3
				c-0.8,0.1-1.6,0.5-2.3,0.7c-19.6,7.1-37.3,2.1-54.2-8.3c-9.3-5.7-14.1-14.8-17.9-24.5c-4.9-12.6-11.8-23.7-21.6-33
				c-6.5-6-12.7-12.4-15.9-20.8c-2-5.2-3.1-10.7-4.6-16.1c-0.4-1.3-0.8-2.5-1.1-3.8c-0.7-3.2-1.6-6.4-1.8-9.7
				c-0.6-10.3-2.5-19.7-10.2-27.6c-6.8-7-11.2-16.1-14.6-25.2c-3.3-8.8-2.4-17.8,0.4-26.7c0.8-2.6,0.9-5.4,1.4-8.1
				c0.1-0.3,0-0.7,0.1-1c4.4-7.9,8.7-15.8,13.3-23.6c5.7-9.4,12.5-18,19.9-26.1c7-7.8,14.2-15.4,21.2-23.2
				c5.6-6.3,11.4-12.6,16.3-19.3c3.5-4.7,6.7-10.1,8.3-15.7c1.4-5.1,0.6-10.8,0.4-16.2c-0.5-11.3-5.2-21.4-10-31.3
				c-6.7-13.7-5.5-27.2,0.3-40.7c2.3-5.3,5.1-10.4,7.7-15.5c0.6-1.2,1.1-2.3,1.8-3.6c-8.8-6.4-12.1-15.8-14.8-25.4
				c-5.1-18.2-10.1-36.4-14.8-54.7c-1.4-5.2-2.5-10.7-2.4-16.1c0.1-10.7,3.8-20.3,11.3-28.3c2.4-2.5,4.9-5,7.6-7.8
				c0-1.8-0.2-4,0-6.1c0.6-6.7,1.3-13.5,1.9-20.2c0.4-4.3,0.9-8.6,1.1-12.9c0.4-11.6,0.7-23.2,1-34.9c0.4-13.5,0.8-26.9,0.9-40.4
				c0.1-4.3-0.9-8.6-0.7-12.9c0.4-9.6,1.1-19.2,1.7-28.8c0.3-4.5,0.7-8.9,1.1-13.4c1-10.7,1.4-21.5,3.2-32
				c2.5-14.2,5.8-28.3,9.2-42.4c3.8-15.5,11.1-29.6,18.3-43.7c5.6-11.1,10.9-22.3,16.7-33.3c8.1-15.2,17.4-29.8,28.3-43.2
				c10.4-12.7,23.3-22.8,37.7-30.7c10.9-6,19.1-14.8,27.1-24c9.3-10.6,16.6-22.9,27.1-32.5c10.6-9.7,22.5-16.7,36.8-19
				c3.5-0.6,7-1.5,10.5-2.2c1.3,0,2.7,0,4,0c1.5,0.4,3,1,4.6,1.2c14,2.2,28,4.6,40.7,11.3c8,4.2,15.7,9.1,23.5,13.8
				c14.1,8.5,29.3,14.4,45,18.9c17.2,4.9,34.6,9.1,51.8,14.2c13.2,4,26.2,8.7,39.2,13.6c12.7,4.7,25.2,9.7,37.6,15
				c11.9,5.1,22.6,12.3,32.8,20.2c10.3,7.9,17.1,18.9,24,29.5c12.2,18.7,26,36.3,39,54.4c6,8.3,12.9,16,18.4,24.6
				c8,12.3,15,25.3,22.7,37.8c5.8,9.4,9.5,19.6,11.7,30.3c0.8,4,1.3,8.1,1.6,12.3c0.5,8.9,0.7,17.9,1.1,26.8
				c0.1,1.7,0.6,3.6,1.4,5.2c7.8,16.3,15.7,32.4,23.4,48.7c4.5,9.6,7.1,19.8,8.2,30.4c0.9,8.7,2.1,17.5,1.9,26.2
				c-0.5,19.2-3.7,38-10.2,56.1c-1.3,3.7-3.1,7.4-5.4,10.6c-4.1,5.7-6.9,11.9-9.1,18.5c-0.7,2.2-1.5,4.5-2.8,6.3
				c-3.8,5.1-4.8,10.7-4.4,16.9c0.3,5.6,0.2,11.3,0,17c-0.1,4.8-0.7,9.6-0.9,14.4c-0.4,10.2,2,20,3.4,30c1,7.6,2.5,15.2,3.6,22.9
				c1.4,9.8,2.6,19.6,3.9,29.4c1.3,9.8,2.8,19.6,4,29.4c2,15.8,7.1,30.7,13,45.5c4.5,11.4,11.6,21.2,18,31.4c4.4,7,8.8,14,13.5,20.8
				c5.5,8,13,12.8,22.9,14c3,0.3,6.1,1.4,8.8,2.8c3.4,1.9,3.9,4.9,3.3,8.9c-1.6,10-7.1,17.7-14.3,24.3c-6.3,5.9-12.9,11.5-19.4,17.2
				c-3.8,3.4-6.4,7.6-8.6,12c-2.6,5.1-5.2,10.3-8.1,15.3c-3.3,5.7-7.9,10.2-14,13c-3.3,1.5-6.4,3.5-9.7,5.1
				c-6.1,3.1-10.8,7.7-14.1,13.6c-6.3,11.4-14.6,20.9-25.9,27.6c1.2,3.1,2.3,6.1,3.8,9.9c4.4,0,9.5,0.2,14.6,0
				c18.8-0.9,36.1,4.6,53,12.1c10.8,4.8,22.1,8.6,33.3,12.6c10.7,3.8,21.4,7.8,32.3,10.7c7.9,2.1,16.2,2.7,24.4,3.7
				c10,1.2,20,2.3,30.1,2.8c7.4,0.4,14.9-0.2,22.4-0.4c2-0.1,4-0.1,6-0.4c8.4-0.9,16.7-1.8,25.1-2.7c3.6-0.4,7.2-1.1,10.9-1.2
				c17.1-0.4,34.3-1.2,51.4-0.9c8.9,0.1,17.4,3.4,25.6,6.8c15.2,6.3,30.4,12.7,45.6,19c14.5,5.9,29.2,11.4,43.6,17.6
				c14,6,26.2,14.7,37.7,24.6c16.2,13.9,31.9,28.3,46.5,43.8c9,9.5,18.3,18.7,25.8,29.6c5.1,7.4,7.3,15.7,8.8,24.4
				c2.2,12.6,3.3,25.4,7.3,37.7c1.4,4.2,1.7,8.7,2.6,13c0.2,0.7,0.8,1.3,1.2,2c0,2.3,0,4.7,0,7c-0.4,1-1.1,2-1.2,3
				c-1,8.7-1.9,17.3-2.9,26c-0.6,5.2-1.2,10.5-2.1,15.6c-0.6,3.7-2.5,4.9-6.1,5.1c-7,0.5-13.7-1.5-20.6-2.5
				c-13.2-1.9-25.8-6.4-38.6-9.8c-12.8-3.4-25.9-5.3-39.2-4.7c-15.2,0.7-29.4,4.7-41.2,14.6c-8.5,7.1-15.9,15.4-21.6,25.1
				c-8.6,14.5-14.4,30.3-21.7,45.5c-6.1,12.8-12.8,25.3-19.5,37.8c-3.6,6.8-7.5,13.5-11.7,20.1c-1.3,2.1-3.5,4-5.8,5.2
				c-3.5,1.9-6.2,0.3-6.7-3.7c-0.8-6.4-0.2-12.8,0.7-19.2c0.8-6,1.4-12.1,2.3-18.2c1.4-8.6,3.1-17.1,4.5-25.7
				c1.3-8.3,2.8-16.6,3.4-24.9c0.8-10.2,0.8-20.6,1.1-30.8c0.1-3.8-0.6-7.4-2.8-10.8c-4.3,1.5-5.6,5.5-7.3,8.6
				c-5.2,9.9-12.5,18.2-19.3,26.9c-3.1,4-5.8,8.4-8.7,12.6c-1.6,2.3-3.3,4.4-5.5,7.3c-1.7-1.8-3.2-2.8-3.8-4.3
				c-1.2-2.7-2.4-5.6-2.5-8.5c-0.4-8.1-2.2-16-0.4-24.3c1.2-5.3,0.7-10.9,1.2-16.3c0.6-5.9,1.5-11.8,2.3-17.6
				c0.9-6.2,2-12.4,2.6-18.6c1-10.2,2.2-20.4,2.5-30.7c0.2-6.7-0.9-13.5-1.4-20.3c-1.1-13.6-5.9-26-12.1-37.9
				c-4.7-9-10.4-17.2-17.2-24.8c-2.5-2.8-4.7-6-6.7-9.2c-2.1-3.1-1.4-6.3,1.2-8.5c3.2-2.7,6.8-5.2,10.6-7.1
				c4.4-2.2,9.2-3.5,13.8-5.3c0.7-0.3,1.3-0.9,2.6-1.7c-1.3-1.5-2.3-2.9-3.5-4.2c-5.2-5.2-10.4-10.3-15.5-15.6
				c-2.4-2.6-5.3-5.2-3.7-10.2c3.7-0.7,7.7-1.5,11.7-2.2c2.8-0.5,5.6-0.9,8.4-1c16.8-0.2,33.1,2.9,49.2,7.4
				c11.9,3.4,23.6,7.2,35.4,10.8c2,0.6,4.1,0.6,6,0.9c1.3-3.2-0.1-5-1.4-7c-5.2-7.7-12.7-12.4-20.7-16.5
				c-11.2-5.8-23.4-8.8-35.7-10.9c-4.9-0.8-9.9-0.8-14.8-1.1c-5.3-0.4-10.6-1.1-15.9-0.9c-15.8,0.6-31.6,1.6-46.5,7.9
				c-2.7,1.1-5.4,2.5-7.9,4c-2.6,1.5-3.3,3.7-2.5,6.8c0.9,3.4,2.7,6.8,0.9,10.7c-5.5,1.1-10.8,0.6-16.2-0.6
				c-11.9-2.6-23.4-6.5-34.5-11.7c-17.6-8.3-36-14.6-55.2-18c-11.3-2-22.7-2-34.2-0.3c-5.5,0.9-11.2,1.1-16.6,2.2
				c-4,0.8-7.8,2.4-10.8,5.6c-1.6,1.8-2.3,3.5-1.2,5.8c1.6,3.5,2.9,7,4.4,10.5c4.4,10.3,1.5,21.3-7.9,27.4
				c-6.6,4.3-13.7,7.9-20.6,11.8c-10,5.6-19.1,12.4-27.4,20.3c-4.3,4.2-8.4,8.5-12.8,12.7c-17.7,17-36.4,32.6-56.4,47
				c-15.4,11.1-31.8,20.2-48.9,28.1c-16.3,7.6-33.2,13.6-50.5,18.6c-17.7,5.1-35.3,10.2-53.2,14.7c-8.8,2.2-17.8,3.4-26.9,3.4
				c-7.9,0-15.9,1-23.8,0.8c-7.2-0.1-14.5-1-21.7-1.9c-9.6-1.2-19.2-2.6-28.8-4.1c-7.3-1.1-14.5-2.7-21.8-3.8
				c-6.2-0.9-12.4-1.6-18.7-2.1c-12.3-1.1-24.5-3-36.3-6.6c-15.3-4.7-30.7-9.8-45.5-15.8c-16.7-6.8-32.3-15.6-46.9-26.3
				c-4.9-3.6-10-6.9-15-10.3c-0.6-0.4-1.1-1-1.2-2.1c9.1,5.7,18.3,11.4,27.4,17.3c15.4,10,31.9,18,49.1,24.2
				c10.6,3.9,21.3,7.7,32.2,10.7c8.8,2.4,17.9,3.9,27,5.4c16.9,2.9,33.9,4.1,50.7,7.7c10.6,2.2,21.5,2.8,32.3,4.4
				c13.4,2,26.9,1.2,40.3,0.7c14.8-0.5,29.4-3.3,43.7-7.4c5.2-1.5,10.6-2.3,15.7-4c6.1-1.9,12.1-4.3,18.1-6.5
				c12.7-4.6,25.5-8.9,37.9-13.9c21.2-8.6,41.7-18.5,60-32.5c11-8.4,22.6-16.3,33.1-25.3c12.7-11,24.5-22.9,36.8-34.5
				c9.3-8.8,19.4-16.5,30.7-22.6c6.4-3.4,12.9-6.9,19-10.8c7.5-4.8,10.2-14.8,6.8-23.1c-1.5-3.7-3-7.3-4.3-11
				c-1.7-5-0.5-8.1,3.7-11.1c5.2-3.6,11.1-5.2,17.1-5.9c9.8-1.2,19.8-2,29.7-2c12.1,0,23.8,2.9,35.4,6.2
				c12.5,3.6,24.7,8.1,36.4,13.9c12.6,6.3,26.1,10,39.8,12.7c1.1,0.2,2.3,0.1,3.5,0.1c1.3,0,2.6-0.2,3.9-0.3
				c-0.6-4.8-1.2-9.3-1.8-13.7c4.1-5.1,9.6-7,15-9c10.1-3.8,20.5-6,31.4-6.1c6.8-0.1,13.6-1.2,20.4-0.8c17.5,0.9,34.8,2.7,51.4,9
				c10.3,3.9,19.6,9.5,27.5,17.2c2.5,2.5,4.3,5.9,6,9c1,1.8,1.3,4.1-0.9,6c-3.9,1-7.8-0.2-11.8-1.4c-13.2-4-26.3-8.3-39.7-11.7
				c-9.7-2.5-19.6-4-29.7-4.9c-6.5-0.5-12.9,0.1-19.3,0.4c-3.2,0.1-6.4,1.3-10.6,2.2c2.8,3.6,5.1,6.8,7.6,9.7c1.5,1.8,3.4,3.2,5,4.9
				c3.4,3.4,6.8,6.8,10.1,10.3c2.8,3,2.2,5.1-1.7,6.7c-3.6,1.5-7.5,2.5-11.1,4.2c-4,1.9-8.1,3.8-11.5,6.5c-4.2,3.3-3.9,5.5-0.6,9.7
				c4.7,5.9,9.7,11.7,14,17.9c10.2,14.5,17.3,30.5,20.1,48c1.5,9.3,1.3,18.8,1.4,28.3c0.1,6.8-0.5,13.6-1.1,20.3
				c-0.5,5.6-1.4,11.1-2.1,16.7c-1.2,8.8-2.4,17.6-3.9,26.4c-1.9,11.5-2.3,23.1-1.9,34.7c0.2,4.3,1.3,8.6,2,12.9
				c0.5,0.1,0.9,0.2,1.4,0.3c1-1.4,2.2-2.6,2.9-4.1c4.7-8.6,11.2-15.9,17.3-23.5c4.2-5.2,7.5-11.3,11.1-16.9
				c1.2-1.8,2.2-3.7,3.5-5.4c2-2.7,4.5-4.5,8.5-3c1.8,3.2,3.1,6.8,3.1,10.9c0,9.7,0.4,19.4-0.1,29c-0.4,7.7-1.8,15.4-3,23
				c-1.8,11.2-3.9,22.3-5.9,33.5c-1.8,10.1-3.1,20.2-3,30.5c0,2.1,0.2,4.2,0.3,6.5c3.2-0.3,5-1.7,6.3-4c3.2-5.6,6.8-11,9.8-16.7
				c6.5-12.4,13-24.9,19.2-37.6c6.6-13.6,12.6-27.6,19.3-41.2c7.7-15.6,18.6-28.6,33.1-38.3c8.4-5.6,18-8.7,28.1-10.1
				c3.7-0.5,7.5-1.2,11.1-2c8.8-2,17.4,0,26.1,0.6c17.5,1.2,33.4,8.8,50.5,11.8c3.9,0.7,7.8,1.2,11.7,1.6c2.5,0.3,4.3-1,5.7-3.1
				c3.4-5.2,5.7-10.6,5.5-17.1c-0.3-7.6,0.6-15.3,0.5-22.9c-0.1-6.6-0.5-13.3-2.8-19.6c-3.8-10.3-5.4-21-6.7-31.8
				c-1.6-13.3-5.5-25.3-15-35.3c-13.4-14.1-26.1-28.9-40-42.5c-11.6-11.4-23.4-22.5-36.7-32.1c-10.6-7.7-22.2-13.7-34.2-18.9
				c-22-9.4-44.7-17.1-66.2-27.8c-8.8-4.4-18.5-7.3-28.1-9.6c-10.2-2.5-20.8-2.4-31.2-1c-1.5,0.2-3,0.4-4.5,0.3
				c-12.8-0.1-25.5,1.4-38.1,2.9c-16.3,1.9-32.8,3.2-49.3,2c-18.3-1.3-36.7-2.8-54.7-6.8c-9.3-2-18-5.3-26.8-8.9
				c-11.5-4.6-23.1-8.9-34.6-13.6c-7.5-3-14.7-6.7-22.2-9.7c-10.5-4.3-21.6-5.9-32.9-5.9c-4,0-8-0.2-12-0.2c-1.6,0-3.2,0.3-4.2,0.4
				c-2.3,10.6-4.2,20.5-6.6,30.4c-2.9,12.3-9.4,22.9-16.6,33c-11.3,15.9-22.9,31.6-34.9,47c-9.6,12.3-19.8,24.1-29.9,35.9
				c-6.2,7.3-12.4,14.8-19.1,21.7c-10.1,10.4-21.6,19.1-34.1,26.4c-24.1,14.1-50.4,22-77.5,27.3c-7.4,1.5-15,2.3-22.5,3
				c-9.7,0.9-19.5,1.6-29.2,2c-10.8,0.5-21.6,1-32.4,0.9c-7.1-0.1-14.1-1.2-21.2-1.8c-4.3-0.4-8.6-0.8-12.9-1.1
				c-26.4-1.7-51.7-8.2-75.9-18.7c-12.4-5.4-24.9-10.7-36.4-18.1c-7.8-5-13.8-11.9-19.8-18.7c-5.6-6.4-11.1-12.8-16.8-19.1
				c-5.4-5.9-11-11.5-16.4-17.4c-4.4-4.8-5.6-10.3-2.8-16.5c0.1-0.2-0.1-0.6-0.1-0.9c-4.4,0.2-9.1-0.4-13.2,0.8
				c-3.6,1.1-7,3.7-9.7,6.5c-5.8,6.2-10.3,13.3-11.7,21.9c-0.6,3.4-1,6.9-1.2,10.3c-0.7,9.2-2.6,18.2-6,26.9
				c-0.8,2.1-1.6,4.4-4.4,5.2c-2.6-1.2-3.1-3.4-3.2-6c-0.3-13-0.7-25.9-1-38.9c-0.1-5.2-1-5.9-5.9-4.1c-6.1,2.2-10.2,6.5-13,12.2
				c-4.3,8.7-9.2,17.1-15.5,24.6c-3.2,3.8-6.7,7.4-10.4,10.7c-8.1,7.2-15.8,14.6-20.2,24.8c-2.3,5.3-4.5,10.7-7.7,15.4
				c-2.8,4.2-6.8,7.7-10.5,11.2c-3.5,3.3-7.5,6.1-10.9,9.5c-12.2,12.3-21.8,26.5-29.5,42c-7.4,14.7-12.3,30-14.9,46.3
				c-1.4,8.6-2.2,17.3-3,26c-2.7,29.2-1.7,58.5-1.8,87.7c0,7.4,1.1,14.8,1.8,22.2c0.7,7.2,1.3,14.5,2.1,21.7
				c0.8,7.2,2.1,14.3,2.7,21.6c0.7,8.3,0.9,16.5,1.1,24.8c0,1.5-1.1,3-1.7,4.4c-0.3,0-0.7,0-1,0c-0.9-1.6-2-3-2.7-4.7
				c-3.5-8.2-6.8-16.4-10.2-24.6c-7.6-18.5-13.5-37.3-16.1-57.2c-1.6-12.5-3-24.9-2.9-37.5c0-1.8,0-3.6,0-5.3
				c-0.3-0.1-0.7-0.3-1-0.4c-4.5,9.2-9.4,18.1-17.2,25c-7.9,7.1-17.5,10.9-26.7,14.8c-3.5-2.1-4.3-5.2-3.9-8
				c1.4-9.2,4.6-17.8,10.5-25.2c1-1.2,1.6-2.7,3-5c-4,0.2-7,0.2-9.9,0.5c-4.6,0.4-9.2,1-13.7,1.6c-2.2,0.3-4-0.5-4.9-2.6
				c-0.8-2,0.4-3.5,1.9-4.7c1.2-0.9,2.3-1.9,3.7-2.5c3.3-1.4,6.7-2.8,10.2-3.8c12-3.6,22.4-9.6,30.7-19.1c4.7-5.3,7.7-11.4,9.3-18.3
				c0.8-3.7-1.7-7.8-5.1-9.2c-3.5-1.4-5-0.7-7.9,3.6c-1.5,2.2-3,4.4-4.1,6.7c-2.2,4.7-5.8,7.5-10.7,8.9c-5.8,1.7-11.7,3.3-17.5,5
				c-10.1,3-20.3,5.9-29,12.1c-8.4,6-15.5,13.3-22.4,21c-8.8,9.8-17.5,19.5-26.3,29.3c-4.5,4.9-9,9.8-13.4,14.7
				c-6.8,7.6-12.4,16.5-20.9,22.5c-4.6,3.2-9.4,6.1-14.2,8.8c-1.9,1.1-4.1,1.2-6-0.5c-2.1-1.9-2.8-4.2-2.1-6.8
				c0.6-2.2,1.6-4.3,2.8-6.3c3.9-6.1,8.1-12.1,12-18.2c8.6-13.3,11.1-27.5,6.5-42.9c-2.5-8.2-8-13.4-15.3-17.2
				c-7.4-4-14.6-8.3-18.4-16.4C0,1329.3,0,1326.7,0,1324z M480,496.1c-0.4,0.2-0.8,0.5-1.2,0.7c-0.4,0.3-0.7,0.6-1.1,0.9
				c-3.1,2.2-6.2,4.3-9.2,6.6c-15.1,11.5-30.2,23-45.2,34.6c-2.6,2-4.4,4.7-5.7,7.7c0.5,0.2,0.7,0.4,0.8,0.3
				c0.9-0.4,1.8-0.9,2.6-1.4c12.7-7.5,24.8-15.9,35.5-26.1c7.3-7,14.5-14.1,21.7-21.2c0.3-0.4,0.6-0.7,0.9-1.1
				C479.4,496.8,479.7,496.4,480,496.1L480,496.1z M448.8,506.8c-0.2,0.3-0.3,0.7-0.5,1c0.3-0.2,0.7-0.3,1-0.5c0.3-0.3,0.7-0.7,1-1
				c0.7-0.3,1.6-0.5,2.2-0.9c8.8-6.4,17.5-12.9,26.4-19.2c9.7-6.8,19.5-13.5,28-22c2.1-2.1,4.1-4.4,6.5-6.2
				c18.1-13.6,36.1-27.4,54.5-40.6c15.6-11.1,31-22.4,48.4-30.8c5.4-2.6,10.1-6.7,15.1-10.2c1.6-0.7,3.3-1.4,4.9-2.1
				c0.6-0.4,1.1-0.9,1.7-1.3c0.6-0.1,1.1-0.2,1.7-0.3c-0.1-0.2-0.2-0.5-0.3-0.7c-0.4,0.4-0.9,0.7-1.3,1.1c-0.7,0.2-1.5,0.5-2.2,0.7
				c-1.7,0.7-3.3,1.3-5,2c-0.8,0.1-1.7,0-2.4,0.3c-15,5.5-29.9,11.1-45,16.5c-16.3,5.8-32.3,12.5-47.7,20.3
				c-9.1,4.6-16.4,10.9-19,21.5c-2,8.3-7,15.3-12.6,21.6c-11,12.3-23.8,22.6-36.4,33.2c-6.2,5.2-11.9,11-17.8,16.6
				C449.4,506.1,449.1,506.4,448.8,506.8z M820.1,464.9c-0.4-0.3-0.7-0.6-1.1-0.9c-0.1-0.6-0.1-1.2-0.2-1.8
				c-0.2,0.1-0.5,0.2-0.7,0.3c0.3,0.5,0.6,1,0.9,1.5c0.3,0.4,0.6,0.7,0.9,1.1c0.3,0.3,0.6,0.7,1,1c3.7,3.2,7,0.7,10.2-0.9
				c10.7-5.5,21.4-10.8,33.2-13.5c3.5-0.8,7-1.8,10.5-2.4c8.4-1.5,16.9-2.6,25.3-4.2c8.6-1.6,17.1-2.1,24.7,3.4
				c0.2,0.1,0.5,0.1,1,0.1c-0.3-2-0.5-3.9-0.8-6c-2.4-1.7-4.5-4-7.2-5.2c-5.2-2.4-10.6-5.3-16.1-5.9c-6.6-0.7-13.5,0.2-20.1,1.4
				c-12.8,2.4-25.1,6.7-36.1,13.9c-6.4,4.2-11.9,9.8-18,14.7c-2,1.6-4.3,2.9-6.5,4.3C820.7,465.6,820.4,465.3,820.1,464.9z
				 M543.8,465.8c-0.3,0.3-0.7,0.7-1,1c-0.3,0.3-0.6,0.7-1,1c-0.2,0.3-0.3,0.6-0.5,0.9c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.3,0.7-0.6,1-1
				c0.3-0.3,0.7-0.7,1-1c0.3-0.3,0.7-0.7,1-1c1-0.7,2-1.3,3-2c0.4-0.3,0.7-0.6,1.1-0.9c0.6-0.4,1.3-0.7,1.9-1.1
				c0.2-0.3,0.3-0.6,0.5-1c-0.3,0.2-0.6,0.3-1,0.5c-0.7,0.3-1.3,0.7-2,1c-0.3,0.3-0.7,0.7-1,1c-1,0.7-2,1.3-3,2
				C544.4,465.1,544.1,465.4,543.8,465.8z M669.8,474.2c0.3,0.2,0.6,0.3,1,0.5c-0.2-0.3-0.3-0.6-0.5-1c-0.3-0.3-0.7-0.7-1-1
				c-0.7-0.3-1.3-0.7-2-1c-1.2-1.1-2.4-2.3-3.7-3.2c-8.7-6-18.9-8.4-29-10.7c-8.8-1.9-17.8-2.2-26.6-0.9
				c-10.4,1.6-19.8,6.1-28.5,11.9c-9.1,6.1-15.3,14.4-19,24.7c-1.8,4.9-0.9,6.8,3.9,8.8c1.2,0.5,2.2,1.3,3.6,2.1
				c-0.2,1.9-0.2,3.7-0.8,5.4c-1,3,0.1,4.9,2.9,5.8c3.8,1.3,7.6,2.4,11.5,3.5c-2.9-3.6-5.9-6.7-9.2-9.4c-2.9-2.4-4.2-5.5-2.7-8.4
				c1.5-3.1,3.1-6.1,4.9-9.1c4.3-7.1,10.7-12.2,17.1-17.1c10.7-8.2,22.8-13,36.3-14c7.9-0.6,15.5,0.7,22.8,3.5
				c5.5,2.1,10.7,5,16,7.5c0.7,0.3,1.3,0.7,2,1C669.1,473.6,669.4,473.9,669.8,474.2z M593,484c-0.8,0.3-1.5,0.5-2.3,0.8
				c-0.2,0.3-0.3,0.7-0.5,1c0.3-0.2,0.7-0.3,1-0.5C591.8,484.8,592.4,484.4,593,484c0.4-0.3,0.8-0.5,1.3-0.7c0.7-0.3,1.3-0.7,2-1
				c0.7-0.3,1.3-0.7,2-1c4.5-1.4,9.1-2.8,13.6-4.2c-0.1-0.4-0.3-0.8-0.4-1.1c-4.9,0.6-9.6,1.7-13.7,4.8c-0.7,0.3-1.3,0.7-2,1
				c-0.7,0.3-1.3,0.7-2,1C593.5,483.2,593.3,483.6,593,484z M844.2,674.2c0.7-0.3,1.3-0.6,2-1c7.5-4.4,14.9-8.8,17.5-17.9
				c-1.6,0.8-2.9,2-4,3.3c-2.2,2.4-4.4,5-6.7,7.4c-2.3,2.3-4.8,4.5-7.3,6.7c-0.7,0.4-1.3,0.7-2,1.1c-0.3,0.3-0.7,0.7-1,1
				c-0.7,0.3-1.3,0.7-2,1c-1,0.6-2,1.3-3.1,1.9c-4.2,2.2-8.6,4.1-12.7,6.6c-6,3.6-9.5,3-13-3c-1.7-3-3.4-6-5.1-9
				c-5-8.8-12.6-14.2-22.1-17.2c-6.4-2-12.3-1.3-17.8,2.8c-3.9,3-4.1,3.3-1.6,8.1c3.8,1,7.5,0.4,11.1-1.1c2.9-1.2,5.9-2.3,9-2.5
				c6.1-0.4,8.6,3.8,5.9,9.4c-4.5,9.1-12.4,13.9-21.5,17.1c-8.6,3-17.1,3.4-25.1-2.1c-2.4-1.7-5.1-3-7.6-4.7c-2.9-2-5.4-4.5-6.3-8.1
				c-1.1-4.3-2.1-8.6-2.7-13c-0.7-5,2.3-8.8,4.9-12.6c3.4-5.1,7.4-9.9,14-10.6c3-0.3,6.1,0.2,9.2,0.5c6.6,0.6,13.3,0.8,19.6,2.2
				c8,1.8,14.6-0.3,21-4.6c8-5.3,16.1-10.4,24.1-15.7c5.3-3.5,11.1-3.6,17.1-2.4c2.7,0.5,5.5,0.3,9.2,0.5c-2.7-4.4-4.9-7.9-7-11.4
				c-3.8-6.4-7.9-12.6-11.2-19.3c-5.7-11.6-10.9-23.4-16.1-35.1c-7-15.7-13.7-31.4-16.2-48.6c-1.3-8.7-1-17.2,2-25.4
				c2.1-5.9,4.7-11.7,10.4-15.3c4.3-2.7,8.7-5.4,12.7-8.5c9-7,17.5-14.6,27.4-20.4c10.6-6.3,21.9-9.8,34.3-10
				c7.3-0.1,14.5,1.4,21.4,3.7c6.1,2,12,4.7,18.1,7.2c0-1.3,0.2-2.8,0-4.3c-0.9-5.5-1.9-11-2.9-16.5c-1.3-7.5-2.5-14.9-4.1-22.3
				c-0.4-1.7-1.9-3.6-3.4-4.4c-3.9-2.2-8-4.1-12.1-5.8c-23.5-9.9-48-16.5-72.6-23.1c-15.7-4.2-31.6-6.7-47.8-6.9
				c-6.8-0.1-13.6,0.5-20.4,1c-7.4,0.5-14.9,0.9-22.2,2c-11.7,1.7-23.4,3.6-35,6.1c-27.8,6-53.8,17.1-78.4,31
				c-14.8,8.4-28.8,18.1-43.1,27.3c-2.9,1.8-5.5,4.1-8.2,6.1c4.3-0.1,8.2-0.9,12.2-1.6c5.8-0.9,11.7-1.9,17.6-2.5
				c5.4-0.6,10.9-1,16.3-1c11.1,0.1,22.4-0.4,33.3,1.2c18.1,2.7,35.8,7.3,52.9,14c7.5,2.9,14.9,6.1,20.8,12.2
				c8.2,8.6,7.8,19.2,3.5,28.2c-1.6,3.3-2,6.2-1.7,9.5c0.5,5.2,1.4,10.5,1.8,15.7c0.9,9.7-4.4,16.8-10.9,22.9
				c-3.4,3.2-8.1,3.8-12.7,2.1c-6.3-2.4-12.2-5.3-19-6.4c-3.7-0.6-6.9-2.3-10.7-0.2c-1.7,1-4.2,0.8-6.3,1.2
				c-5.6,1.1-11.4,1.8-16.9,3.5c-8,2.4-16,3.7-24.3,3.6c-1.8,0-3.6,0.2-5.5,0.3c-1.8,0.1-3.5,0-5.3,0c-4.1,0-8.4-0.6-12.4,0.2
				c-4.5,0.8-8.7,3.1-13.1,4.2c-7.9,2-15.8,2.8-23.7-0.4c-4.1-1.7-8.5-2.6-12.9-3.3c-9.9-1.5-20.2,0-29.7-3.7
				c-1.3,7.6-2.4,14.9-3.7,22.2c-1.4,7.9-1.8,16.2-4.5,23.7c-2.4,6.6-3.8,12.8-2.5,19.6c0.6,3,0.8,6,1.3,9.7
				c9-7.4,18.4-12.6,28.1-17.7c9-4.8,18.1-9.3,27.8-12.9c11.6-4.3,23.7-5.4,35.9-6.4c2.6-0.2,5.2,0.8,7.8,0.9
				c9,0.1,17.7,1.8,26.4,3.6c12.6,2.6,24.5,7.4,36.2,12.6c7.8,3.5,16,6.1,22.9,11.4c5.6,4.4,11,8.9,13.1,16.1
				c3.3,11.5,4.6,23.3,5.4,35.1c0.5,7.9-0.7,15.9-0.8,23.8c-0.1,8.7-2.1,16.9-5.9,24.7c-4.7,9.6-10.6,18.5-17,27.1
				c-5.7,7.7-13.5,11.5-22.7,12.1c-10,0.6-18.8-3.4-26.6-9.1c-8.7-6.3-17.1-13.1-25.7-19.5c-2.6-2-5.1-4.3-8.7-5.2
				c0,0.9-0.1,1.4,0,1.8c2.9,9.2,2.2,18.6,1.5,27.9c-0.8,10.4-1.9,20.7-2.8,31c-0.1,1.3,0.3,2.8-0.1,4c-2.5,7-0.4,14,0.4,20.7
				c1.5,11.6,4.5,23.2,7.8,34.5c3.8,13.3,9.4,26,16.3,38.2c7.7,13.6,18.9,24.2,30.3,34.7c11.6,10.6,24.8,18.6,38.4,26.3
				c5.9,3.3,11.8,6.7,17.4,10.4c4.2,2.8,8.3,4.9,13.4,3c0.9-0.3,2-0.1,3-0.1c10.3-0.3,20.6-0.8,30.9-1c13.3-0.2,24.5-5,33.2-15.2
				c2.7-3.1,5.5-6.2,7.3-9.8c1.7-3.3,2.4-7.2,2.9-10.9c0.4-2.7,0-5.6-0.5-8.3c-1-5.3-5-8.3-9-11.3c-2.3-1.8-4.8-3.5-7.1-5.4
				c-5.6-4.5-6.5-11.8-3.8-20c1.7-5.3,3.3-10.6,5-15.9c3.1-9.7-4.1-16-12.5-16.6c-11.2-0.8-22.6-1.1-33.8-1
				c-6.3,0-11.1-2.1-15.3-6.7c-3.2-3.6-3.2-7.3-1.7-12.2c4.2,0.4,8.3,0.7,12.3,1.3c7.6,1.1,15.2,2.8,22.9,3.6
				c7.6,0.8,15.3,1.8,22.8,1.1c16-1.5,28-10.4,37.4-22.9c2.4-3.3,5-7.1,3.1-12c-0.7-0.1-1.3-0.3-2-0.2c-13.1,0.1-26.1,1.8-39,4.1
				c-15.3,2.7-30.7,4.9-46.2,5.8c-3.6,0.2-7.3,0.5-10.9,1.2c-10.5,2.1-20.9,4.5-31.4,6.5c-4.4,0.8-8.9,1-13.3,1.2
				c-3.4,0.1-6-1.7-6.6-4.1c-0.7-3-0.2-5.8,2.6-7.4c2.1-1.2,4.4-2.3,6.8-2.8c7.7-1.6,15.5-2.8,23.2-4.5c11.5-2.6,23-5.7,34.5-8.2
				c8.1-1.7,16.3-3.4,24.6-2.1c10.2,1.6,19.6,0.7,28.8-4.4c4.1-2.3,8.9-3.6,13.5-4.7c6.6-1.5,13.9,2.3,17.1,8.2
				c1,1.8,2.1,3.6,3.1,5.4c4-1.2,7.3-2.5,8.1-5.9c-2.2-2-4-3.7-6-5.3c-1.1-0.9-2.4-1.7-3.7-2.1c-5.9-1.5-11.9-2.7-17.8-4.2
				c-3.4-0.8-4.8-2.5-4.8-6c0.2-8.4,0.2-16.9,1.1-25.3c0.9-8.2,4.1-15.7,10.5-21.4c4.4-3.9,8.9-7.8,13.3-11.8c1-0.7,2-1.3,3-2
				c0.7-0.3,1.3-0.7,2-1C843.6,674.9,843.9,674.6,844.2,674.2z M903,933c0.8-0.2,1.6-0.4,2.4-0.6c8.9-2.1,16.3-6.9,22.9-13.1
				c0.2-0.3,0.3-0.7,0.5-1c-0.3,0.2-0.7,0.3-1,0.5c-7.7,4.3-15.3,8.6-23,13C904.2,932.2,903.6,932.6,903,933
				c-0.2,0.2-0.5,0.3-0.7,0.4c0.1,0.1,0.3,0.3,0.4,0.4C902.8,933.5,902.9,933.2,903,933z M897,936c-0.7,0.2-1.5,0.5-2.2,0.7
				c-8.9,2.7-17.9,4.4-27.2,4.1c-9-0.3-17-3.1-24.9-7.1c-7-3.5-12.8-8.6-18.4-14c-0.3-0.2-0.6-0.3-1-0.5c0.2,0.3,0.3,0.6,0.5,1
				c1.2,7.5,4.9,13.8,9.4,19.8c2.2,3,4.9,5,8.8,5.4c4.4,0.4,8.7,1.4,13.1,1.7c4.3,0.3,8.6-0.1,12.9,0.1c4.4,0.3,8.6-0.6,12.5-2.3
				c5.1-2.2,9.9-5.1,14.8-7.7C895.8,936.8,896.4,936.4,897,936c0.5-0.2,1-0.3,1.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7
				C897.9,935.3,897.4,935.7,897,936z M577,493c1.2,0.3,2.2,0.2,1.6-2.1C577.8,491.9,577.4,492.4,577,493l-0.2,0.2L577,493z
				 M279.7,1188.7c-1,0.7-2,1.3-3,2c-2,0.9-4,1.6-5.8,2.6c-6.5,3.5-13.3,6.1-20.8,5.4c-6.2-0.6-12.4-1.4-18.6-2.4
				c-7.7-1.3-15.4-1.9-23,0.2c-8.8,2.5-17.7,5.1-26.3,8.3c-21.1,7.8-41.4,17.4-60.4,29.6c-20.2,13-40.1,26.2-60.1,39.5
				c-15.5,10.3-30.6,21.2-43.3,34.9c-3.8,4.1-7.1,8.7-10,13.5c-3,5-2.3,8.1,1.9,11.8c2.2,1.9,4.7,3.7,7.3,5
				c10.2,5.1,18.3,12.1,22.8,23.1c3.9,9.7,6.3,19.3,1.9,29.2c-4.9,11.1-10.4,21.7-19.6,30.2c-3,2.7-5,6.5-7.1,10
				c-1.4,2.3-1.5,4.7,1.2,6.6c11.2-3.5,19.5-10.9,27-19.5c4.6-5.4,9-11.1,13.8-16.3c7.1-7.7,14.5-15.2,21.7-22.8
				c2.8-3,5.6-6.1,8.2-9.3c6.9-8.7,13.7-17.4,22.4-24.5c7.1-5.9,14.8-10.6,23.4-13.4c9.8-3.3,19.8-6.1,29.7-9
				c4.1-1.2,7.5-3.1,9.5-7.3c1.4-3,3.2-5.8,5-8.5c3.6-5.3,9.3-5.7,14-1.3c4.3,4,4,8.9,2.6,13.8c-4.6,16.7-16.6,26.6-31.6,33.6
				c-5.5,2.6-11.5,4.1-17.2,6.3c-2.2,0.8-4.2,2.2-6.2,3.3c1,0.8,1.7,1,2.3,0.9c5.4-0.5,10.8-1,16.2-1.7c4-0.5,7.5-0.1,10.3,3.8
				c-1.4,2.4-2.6,5-4.2,7.1c-3.9,5-6.6,10.6-8.4,16.6c-1,3.3-0.9,6.7,0.1,10.4c1.4-0.4,2.5-0.6,3.5-1.1c6.1-3.4,12.2-6.6,18.1-10.3
				c2.8-1.7,5.4-4.1,7.3-6.7c4.1-5.9,8.1-12.1,11.5-18.4c2-3.8,3.6-8.2,4.1-12.5c0.9-8.2,0.7-16.6,1.4-24.8
				c0.6-7.2,1.8-14.4,2.7-21.6c0.8-6.2,2.3-12.4,2.1-18.6c-0.4-10.4-1.8-20.7-2.9-31c-1-9.3-2.6-18.6-0.9-28.1
				c1.1-5.8,2.7-11.3,6.5-15.8c1.8-2.2,4.5-4.3,7.2-4.9c5.9-1.5,10.6,3,10.9,9.7c0.4,7-0.8,13.8-2.7,20.5c-4,14-7.9,28.1-11.8,42.2
				c-1,3.8-1.8,7.6-2.2,11.5c-0.2,2.2,0.6,4.6,0.6,6.9c-0.2,6-0.4,11.9-1,17.9c-0.7,7-1.3,14.1-2.7,21c-2.5,12.3-5.5,24.5-4.3,37.2
				c0.4,3.8,0.7,7.6,1,11.4c0.7,7.4,1.3,14.8,2,22.2c1,11,3.4,21.8,7,32.2c4.6,13.5,9.8,26.9,14.8,40.3c1,2.7,2.4,5.2,3.6,7.8
				c0.3-0.1,0.6-0.2,1-0.3c0-2.1,0.2-4.3,0-6.4c-0.5-4.7-1.3-9.5-1.8-14.2c-0.8-7.2-1.4-14.5-2.1-21.7c-1.2-11.6-2.9-23.2-3.8-34.9
				c-0.8-11.1-1-22.3-1-33.4c0-8.6,0.8-17.3,0.9-25.9c0.1-13.9,0.5-27.9,2.1-41.7c0.9-8.5,1.8-17,3-25.5c2.3-15,7-29.4,13.6-43.1
				c8.8-18.1,20.1-34.5,34.5-48.6c1-0.7,2-1.4,2.9-2.2c0.2-0.3,0.3-0.7,0.5-1C280.4,1188.4,280.1,1188.6,279.7,1188.7z M856.7,495.7
				c-3.4,3.5-3.4,3.5-1.9,7.9c0.9-2.8,1.7-5,2.5-7.3c0.2-0.3,0.3-0.7,0.5-1C857.4,495.4,857.1,495.6,856.7,495.7z M536.9,466.8
				c-1.6,2.1-3.6,4-2.1,8.2c1.1-1.8,2.1-2.8,2.4-3.9c0.3-1.2,0.1-2.6,0.1-3.9c0.1-0.2,0.2-0.4,0.2-0.6
				C537.2,466.7,537,466.7,536.9,466.8z M923.1,395.9c-0.2-0.5-0.4-1.1-0.5-1.6c-0.2,0.1-0.5,0.2-0.7,0.4c0.3,0.5,0.6,1,1,1.5
				c0.7,4.8,1.4,9.5,2.1,14.3c0.4-0.1,0.8-0.1,1.2-0.2C926.5,405.2,925.8,400.4,923.1,395.9z M589.8,424.8c-7,0.4-13.8,1.1-19.5,5.9
				c2.1-0.2,4-0.9,5.9-1.4c4.7-1.3,9.3-2.6,14-3.9c1.1-0.1,2.3-0.3,3.4-0.4c-0.1-0.4-0.2-0.7-0.2-1.1
				C592.2,424.1,591,424.4,589.8,424.8z M891,478c0.9,0.4,1.8,0.8,2.7,1.2c0.1-0.4,0.2-0.8,0.3-1.2C893,478,892,478,891,478
				c-5.1-3-16.4-3.2-21.2-0.2c-0.5,0.2-1,0.5-1.6,0.7c0.1,0.2,0.2,0.4,0.4,0.7c0.5-0.3,1.1-0.6,1.6-0.9c6.4,0.3,12.9-1.9,19.3-0.1
				C890,478.3,890.5,478,891,478z M893.3,520.3c9.3-5.3,13-16.6,6.5-22.5c-2.4,7.6-4.7,14.8-7,22c-0.2,0.3-0.3,0.7-0.5,1
				C892.6,520.6,892.9,520.4,893.3,520.3z M322.2,1134.2c12.6-9.3,20.1-22.4,27.1-35.9c0.2-0.3,0.3-0.7,0.5-1
				c-0.4,0.1-0.7,0.3-1.1,0.4c-0.7,0.4-1.6,0.6-2.2,1.1c-7.5,6.8-14.4,14.2-19.6,22.9c-2.2,3.7-3.5,8.1-5.1,12.1
				c-0.1,0.3-0.2,0.7-0.3,1C321.7,1134.6,321.9,1134.4,322.2,1134.2z M726.7,462.2c0.2,0.8,0.4,1.6,0.6,2.3c0.3-0.1,0.5-0.1,0.8-0.2
				c-0.3-0.9-0.6-1.8-0.8-2.7c0-4.6-1.7-8.3-5.2-11.4c-4.9-4.4-10.6-7.4-16.7-9.5c-13.2-4.4-26.6-8.5-39.9-12.7
				c-2.3-0.7-4.7-0.9-7-1.4c0.4,1,0.9,1.2,1.5,1.4c17.2,5,34.1,11,49.8,19.9C716.4,451.8,722.3,456.1,726.7,462.2z M538.7,450.7
				c-0.7,0.3-1.6,0.5-2.2,1c-6,4.6-12.1,9.2-18.1,13.9c-1.5,1.1-2.9,2.4-4.2,3.4c6.3,6.9,6.7,15.4,9.2,23.6c0.4-0.9,0.7-1.4,0.9-2
				c1.7-6,3-12.1,5.3-17.8c2.8-7.3,6.4-14.4,9.7-21.5c0.2-0.3,0.3-0.7,0.5-1C539.4,450.4,539.1,450.6,538.7,450.7z M900.8,479.2
				c0.2,0.2,0.4,0.4,0.6,0.6c-0.1-0.3-0.2-0.7-0.3-1c-0.1-1.2,0.2-2.9-0.4-3.4c-2.5-2.1-5.2-4.2-8.1-5.4c-6.6-2.6-13.4-1.1-20,0.1
				c-16.6,3.1-30.1,12-42,23.6c-1.4,1.4-2.4,3.2-3.7,4.9c3.5,1.6,5.6,0.5,8-1.6c4.5-3.9,9.2-7.6,14.1-10.8c4-2.5,8.9-3.7,12.7-6.4
				c9.7-6.9,19.8-8,30.8-3.8C895.3,477.1,898.1,478.1,900.8,479.2z M908,481.1L908,481.1c-0.3,4.1,3.2,3.9,5.8,4.7
				c0.5,3,1,5.9,1.5,9.1c0.4-0.4,0.7-0.6,1-0.9c0.1-0.1,0.2-0.2,0.4-0.3c0.1,0,0.2,0.1,0.6,0.2c-0.6,2.6-1.7,5.3-1.9,8
				c-0.4,6.1-3,10.3-8.5,13.1c-3,1.6-5.5,4.1-8,6.5c-0.8,0.7-1.2,2.6-0.9,3.6c2.5,7.3,6.6,13.5,13.5,17.4c1.7-1.1,3.1-1.9,4.6-2.9
				c0-2.9,0.7-5.9-0.1-8.5c-1.8-5.6-0.7-11.2,0.8-16.2c2.2-7.7,2.5-15.3,2.5-23.1C919.2,485.2,914.9,481.1,908,481.1z M315.7,1128.7
				c-5.6,4.7-11,9.6-16.7,14.1c-5.3,4.2-9.5,9.3-12.3,15.3c-2.6,5.7-4.8,11.6-7.1,17.4c-0.4,0.9-0.4,2-0.6,3
				c0.3,0.2,0.6,0.4,0.9,0.6c4.6-3.7,9.3-7.3,13.9-11.1c6.8-5.5,11.5-12.8,15.4-20.4c2.9-5.8,4.7-12.2,7.1-18.3
				c0.2-0.3,0.3-0.7,0.5-1C316.4,1128.4,316.1,1128.6,315.7,1128.7z M662.2,485.8c-0.3-0.2-0.7-0.3-1-0.5c0.2,0.3,0.3,0.7,0.5,1
				c2.4,3.7,3.4,7.8,3,12.3c-0.8,9.9-7.5,16.5-16.1,17.2c-3.5,0.3-7,0.3-10.4-0.2c-12.3-1.8-16.3-12.1-14.6-19.8
				c0.8-3.4,0.2-6-1.4-9.3c-2.8,0.5-5.5,1-7.9,1.4c-1.6,5.2-2.9,9.9-4.4,14.7c0.6,0.6,1.3,1.4,2,2.1c2.8,3,6,5.3,6.5,10.1
				c0.3,2.5,2,5.4,5.8,5.6c3.9,0.2,7.8,1.6,11.6,1.7c6.9,0,13.9-0.2,20.8-1c6.8-0.8,13.6-2.4,19.5-6.3c2.8-1.8,5.8-3.1,9.1-1.5
				c3.5-4.8,2.2-13.4-3.1-16.8C675.8,492.5,668.9,489.3,662.2,485.8z M941.8,900.8c-5.9,2.5-11.9,4.7-17.6,7.6
				c-4.2,2.1-8.1,5-12.1,7.6c-14,9.1-28.7,15.1-46.1,11.9c-16.7-3-30.1-11.1-40.8-24c-0.7-0.8-1.5-1.5-2.7-2.6
				c-0.4,1.6-0.5,2.6-0.8,3.4c-2.2,5.3-0.6,9.6,3.5,13.3c1.9,1.7,3.5,3.6,5.3,5.3c9,8.3,19.9,13.2,31.8,14.7
				c15.5,2,30-2.3,43.5-10.1c8.4-4.8,17.2-9,25.8-13.5c1.2-0.7,2.7-1.4,3.4-2.4c2.5-3.5,4.8-7.1,7.2-10.7c0.2-0.3,0.3-0.7,0.5-1
				C942.4,900.4,942.1,900.6,941.8,900.8z M912.7,292.3c0.3,0.2,0.7,0.3,1,0.5c-0.2-0.3-0.3-0.7-0.5-1c-1.9-5.4-3.4-11-5.8-16.1
				c-4-8.5-8.5-16.7-12.9-25c-8.8-16.7-17.8-33.4-29.3-48.6c-7.7-10.1-15.5-20.1-23.4-29.9c-2.1-2.6-4.7-4.7-7.8-7.7
				c1.9,8.8,6.6,18.5,12.8,28.2c8.7,13.6,17.9,26.9,24.6,41.7c4.1,8.9,7,18.1,7.5,28c0.1,1.4,0.6,3,1.5,4c1.6,1.9,3.5,3.5,5.4,5
				C894.7,278.3,903.7,285.3,912.7,292.3z M806.2,520.8c0,0.9,0,1.9,0,2.8c0.3-0.1,0.6-0.1,0.9-0.2c-0.4-0.8-0.7-1.7-1.1-2.5
				c0-3.7,0.1-7.3,0-11c-0.2-5.6,0.1-11.1,4.1-15.6c1.7-2,2.9-4.6,4.9-6.2c7.3-5.7,14.7-11.3,22.3-16.5
				c10.5-7.2,21.3-13.5,35.1-12.6c10.4,0.7,19.2,3.6,26.8,10.9c3.1,3,6.3,6.1,10.1,7.8c5.8,2.6,10.9,5.6,14.6,11.7
				c0.9-2.8,1.9-4.9,2.3-7.2c1.2-7.2,1.5-14.3,1.4-21.6c-0.1-6.1-2.1-9.6-7.4-11.7c-0.9-0.4-1.7-1.1-2.6-1.3c-3.2-0.7-6.5-2-9.6-1.6
				c-7.3,0.9-14.6,2.3-21.8,3.9c-19.4,4.3-38.8,8.8-56.4,18.4c-5.7,3.1-9.3,2.1-13.5-2.8c-0.3-0.3-0.8-0.5-1.6-0.9
				c-5.7,5.9-10.2,12.4-12.9,20.2c-1.3,3.8-2.4,7.6-1.9,11.6c0.5,4.1,0.9,8.2,2.1,12.1C803.2,512.7,803.3,517.2,806.2,520.8z
				 M541,465.9c2.7-1.9,5.1-4.1,8-5.6c9.6-5,19.4-9.7,29.1-14.6c15.7-8,32.5-9.7,49.7-8.4c10.3,0.8,20.6,2.7,30.9,3.8
				c12,1.2,23.6,4.3,35,8.2c9.8,3.3,18.6,8.5,25.1,17.1c1.6,2.1,3.6,3.9,5.6,6.1c2.9-6.9-1.7-13.6-6.3-16.8
				c-11.7-8.2-24.6-13.9-37.9-19c-12.6-4.8-25.7-8.1-38.9-10.7c-5.8-1.1-11.7-1.8-17.6-2.1c-14.3-0.8-28.1,2.7-41.9,5.8
				c-13.4,3.1-24.9,9.4-34.4,19.3C542.9,453.6,541.4,459.7,541,465.9L541,465.9z M653.4,366.4c1.7-0.6,3.4-1.2,5.1-1.8
				c2.6-1.1,5-2.5,7.7-3.3c21.1-6.6,42.3-12.5,64.4-15.2c10.5-1.3,21-2.8,31.6-2.8c6.8,0,13.7,0.1,20.5,0c10-0.2,19.9,0.9,29.5,3.3
				c30.5,7.7,60.8,16,89.3,29.5c3.6,1.7,7.4,3.3,12,5.3c-5.9-8-11.1-15-15.7-22.7c-9.4-15.8-18.3-32.3-33.2-44.1
				c-6.6-5.3-13.2-10.4-20.6-14.4c-1.1-0.6-2.3-0.9-3.7-1.4c-1.5,6.6,0.2,12.6,2.2,18.3c2.1,6.2,5,12.2,7.6,18.2
				c1.7,4,1.6,4.5-1.6,7.2c-1.1-0.5-2.4-0.9-3.5-1.7c-15.2-10.3-31.3-19.1-47.4-27.9c-16-8.8-32.8-14.6-50.9-16.1
				c-4.2-0.3-8.6,0.3-12.8,1.1c-4.6,0.8-5.5,3-4.3,7.5c0.9,3.5,1.7,7,2.4,10.6c0.8,4.1-0.7,7.5-4.3,9.3c-2.8,1.3-5.7,2.7-8.7,3.4
				c-7,1.6-14.1,3-21.3,4.2c-8.9,1.4-17.9,2.5-26.9,3.9c-5.3,0.8-10.7,1.9-15.9,3.1c-2.2,0.5-4.3,1.7-5,4.1
				c0.5,6.7,8.8,7.5,9.9,13.5c-1.2,3.9-4.1,6-7,8.2c-0.1,0.3-0.3,0.7-0.4,1C652.7,366.7,653,366.5,653.4,366.4z M919.5,671.6
				c-2.1,5.8-4.1,11.7-6.4,17.5c-3.6,8.7-8.1,16.9-13.3,24.7c-4.7,7.2-10.1,13.5-18.2,17c-4.8,2.1-9.7,1.6-12.7-1.9
				c-2.5-3-4.5-6.6-4.7-10.7c-0.5-12.9,0.4-25.8,2.7-38.5c0.7-4,0.9-8.2,1.2-12.1c-5.6,3.5-10.7,6.7-15.8,9.9
				c-7.2,4.4-14.5,8.5-21.5,13.2c-5.2,3.5-9,8.3-11,14.4c-3,8.9-3.4,18.1-3,27.4c0.1,2.5,1.2,4.3,4.1,4.8c2.9,0.5,5.8,1.3,8.6,2
				c5.9,1.6,12.7,1.3,16.1,8c0.1,0.3,0.5,0.4,0.8,0.6c4.3,3.4,4.7,8.1,0.8,11.9c-1.5,1.5-3.5,2.6-5.4,3.4c-2.6,1.1-3.5,2.9-4.1,5.6
				c-0.9,4-1.7,8.4-4.1,11.4c-4.5,5.7-9.8,10.8-15.3,15.5c-10.2,8.7-22.8,11.6-35.6,11.7c-9.5,0.1-19-2.1-28.5-3.3
				c-4.2-0.5-8.4-1.1-12.6-1.7c-0.8,5.8,0.7,8.1,6.1,8.6c6.4,0.6,12.9,0.8,19.3,1.1c0.7,0,1.4,0.1,2-0.1c6.6-1.9,13.2-1.2,19.6,0.2
				c9.3,2.1,12.8,8.1,12.1,16c-0.3,3.4-1.6,6.7-2.6,10c-1.4,4.4-2.8,8.7-4.4,13c-1.9,5.3-1,9.8,3.3,13.4c2.6,2.2,5.3,4.4,8.1,6.4
				c7,5,11,11.4,9.8,20.3c-0.3,2.1-0.7,4.2-1,6.3c-0.2,0.3-0.3,0.7-0.5,1c0.3-0.2,0.6-0.3,1-0.5c4.2-5.7,8.7-11.2,12.5-17.2
				c13.7-21.6,27.6-43.1,40.7-65c6.7-11.2,12.4-23,17.6-34.9c6.3-14.3,11.8-29,17.4-43.6c3.1-8.1,6.4-16.3,8.3-24.7
				c1.9-8.4,4.7-16.4,6.9-24.6c1.4-5.2,1.8-10.7,2.7-16c0.3-1.2,0.5-2.4,0.8-3.6c-0.3-0.1-0.6-0.1-0.9-0.2
				C919.9,669.5,919.7,670.6,919.5,671.6z M718.8,928.2c0.3,0.2,0.6,0.4,0.9,0.6c-0.2-0.3-0.4-0.6-0.6-0.9c-2.4-1.4-4.8-2.8-7.3-4.2
				c-7.6-4.3-15.4-8.3-22.9-12.8c-14.3-8.6-26.8-19.4-38.6-31.2c-8.7-8.6-15.5-18.5-20.9-29.4c-7.8-15.7-13.7-32.1-17.2-49.3
				c-1.7-8.2-3.5-16.5-3.9-24.8c-0.5-10.4,0.4-20.9,0.9-31.3c0.2-3.6,0.5-7.3,1.1-10.8c1.8-10.1,2.5-20.2,1-30.5
				c-1.1-7.2-3.8-12.4-11-14.7c-0.5-0.1-0.9-0.5-1.3-0.6c-18.3-5.8-36.5-11.8-54.9-17.4c-12.5-3.8-24.3-9-33.2-18.8
				c-9.9-10.9-13.2-22.6-5.8-35.7c2.9-5.2,6.3-10,9.7-15.5c-2.3-4.2,1.1-10-2.7-15.1c-0.8,1.3-1.7,2.3-2.1,3.5
				c-2.2,5.3-4.2,10.8-6.4,16.1c-1.5,3.6-3.8,7-4.8,10.8c-3.8,13.9-7.4,27.9-10.7,42c-1.8,7.8-3.1,15.8-4.4,23.7
				c-0.9,5.5-1.5,11.1-2.1,16.7c-0.7,7.2-1.8,14.5-1.8,21.7c0,18.1,0.5,36.2,1,54.4c0.1,2.1,1,4.6,2.3,6.3
				c12.8,16.7,25.5,33.4,38.8,49.7c12,14.7,26.4,27.1,41.5,38.6c7.2,5.5,14.4,11.2,21.8,16.5c5.1,3.7,10.2,7.4,15.7,10.4
				c18.3,9.8,38.2,15.1,58.2,20c14.7,3.6,29.4,6.7,44.2,9.8C708.4,927.1,713.6,927.5,718.8,928.2z M472.6,803.4
				c0.4,0.1,0.7,0.3,1.1,0.4c-0.2-0.4-0.3-0.7-0.5-1.1c0-4.9,0.1-9.8-0.1-14.7c-0.2-5.3-0.7-10.6-1-15.9c-0.6-9.2-1.8-18.5-1.8-27.7
				c-0.1-13.8,0.4-27.6,1-41.3c0.3-7.7,1-15.5,2.1-23.2c1.6-10.2,3.9-20.3,5.7-30.5c2.6-14.6,6.4-28.8,11.6-42.8
				c5.6-14.8,11.3-29.5,16-44.6c4.5-14.6,7.8-29.7,7.3-45.2c-0.2-7.1-1.4-14.1-2.5-21.1c-0.7-4.4-1.8-8.8-3-14c-2,1.9-3.2,3-4.3,4.2
				c-9.8,10.4-19.5,20.9-29.4,31.3c-3.2,3.4-6.9,6.2-10.3,9.4c-1.5,1.5-3.2,3.2-3.7,5c-0.9,3.1-1.1,6.5-1.2,9.8
				c-0.2,6.8-0.4,13.6,0,20.4c0.4,6.7,0.8,13.6,2.4,20.1c3,12.8,4.1,25.7,4.5,38.7c0.2,8.2,0.2,16.3,0,24.5c-0.1,4.4-0.1,9-1.1,13.3
				c-4.4,19-9.5,37.9-16.2,56.2c-7.3,19.8-14,39.5-15.8,60.8c-0.8,9.2-0.7,18.2,0.5,27.2c1.6,12.4,3.9,24.7,5.9,37
				c1.5,9.6,1.5,19.1,0,28.7c-2.1,13.7-6.1,26.9-12.8,39.1c-4.5,8.2-10.6,14.8-19.8,17.7c-2.6,0.8-5.4,1.1-8.1,1
				c-5.2-0.2-7.6-4.2-9.9-8.1c-1.7-3-3-6.3-5.1-9c-2.2-2.9-3.9-6.6-8.3-7.5c-7,7.9-16.1,12.2-25.7,15.5c-9.9,3.4-19.8,2.9-28.3-3.7
				c-3.8-2.9-6.5-7.3-9.5-11.2c-3.3-4.3-6.1-9-9.6-13.1c-5.9-6.8-10.9-14.1-14.4-22.4c-4.3-10.2-8.6-20.3-13.4-30.2
				c-2.3-4.7-5.4-9-10.6-11.6c-3,5.1-4.3,10.3-5.6,15.6c-1,4.1-1,8.6,0.5,12.1c2.8,6.7,6.6,12.9,10.5,19c3.3,5.1,7.8,9.5,10.9,14.6
				c4.8,7.9,5.2,17.2,7,26c2.4,11.7,4,23.4,12,33c7.2,8.7,13.7,17.9,21.2,26.3c4.4,4.9,7.8,10.1,10.4,16.1c2.2,4.9,5,9.8,8.5,13.8
				c6.7,7.8,16.4,11,25.9,13.8c7.6,2.2,15.2,2.2,22.8-0.3c3.4-1.2,7-2.2,10.5-2.5c10.6-1,20.7-3.8,30.7-7.2
				c7.7-2.6,15.5-4.7,23.7-4.8c3.8,0,6.1-1.2,8.1-4.4c6.3-10,10.5-20.9,14.5-32c6.2-17.1,10.6-34.8,11.2-52.9
				c0.6-16.8-0.4-33.6-0.9-50.4c-0.2-5.4-1-10.9-1.9-16.2c-0.7-4-2.3-7.9-2.8-12c-0.8-7.1-3-13.8-5.8-20.4
				C474.4,809.3,473.6,806.3,472.6,803.4z M845.8,336.2c0.3,0.2,0.6,0.3,1,0.5c-0.2-0.3-0.3-0.6-0.5-1c-0.6-1.4-1.1-2.7-1.7-4.1
				c-4.2-9.7-7.6-19.7-7.4-30.5c0-2.4,0.8-4.1,3.2-5c0.5,0.2,0.9,0.3,1.4,0.5c8.6,3.6,16.1,8.9,23,15c5.9,5.2,12,10.6,16.6,17
				c7.8,10.8,14.7,22.2,21.4,33.6c5.7,9.7,12.9,17.8,22.9,22.6c7.3,3.5,13.9,7.2,18.1,14.3c0.2,0.5,0.5,1,0.7,1.6
				c0.2-0.1,0.5-0.2,0.7-0.3c-0.3-0.6-0.6-1.1-0.9-1.7c-0.8-3.2-1.5-6.4-2.5-9.6c-5.9-19.3-11.3-38.6-10.6-59.1
				c0-1.1-0.1-2.4-0.6-3.3c-2.7-4.3-5.2-8.9-8.6-12.6c-12.5-13.4-26.2-25.5-40.6-36.7c-4.8-3.7-9.6-7.7-14.9-10.6
				c-14-7.4-28.3-14.1-43.2-19.7c-20.9-8-42.3-12.8-64.8-12.4c-4.8,0.1-9.6,0.5-14.4,1c-7.1,0.6-14.3,0.9-21.2,2.3
				c-14.5,2.9-29,6.2-43.3,9.8c-21.9,5.6-43.6,12.2-63.8,22.6c-14.8,7.6-29.2,16.1-43.5,24.7c-18.1,10.7-34.3,24.2-51,36.9
				c-2.2,1.7-2.6,3.6-1.9,6.5c4,17,12.8,31.4,24.4,44.1c2.6,2.9,2.8,4.7,0.1,7.7c-2.9,3.2-6.1,6.2-9.4,8.9
				c-6.1,4.9-12.3,9.5-18.5,14.1c-9.5,7.1-14.8,6.3-21.8-3.4c-0.2-0.3-0.4-0.6-0.5-0.8c-8.1-12.6-15.5-25.6-17.7-41.2
				c-7.4,5.5-14.5,10.6-21.4,15.9c-0.9,0.7-1.3,2.2-1.5,3.4c-1.3,7.7-4.2,14.8-8.3,21.5c-8,12.8-19.4,22.6-29.1,33.9
				c-9.2,10.7-17.3,21.9-23,34.9c-4.7,10.6-4.7,21.9-3.2,32.7c1.7,11.8,5.6,23.2,8.7,34.8c0.2,0.9,1.3,1.6,1.9,2.3
				c8-6.4,15.7-12.4,23.3-18.5c3.5-2.8,7.1-5.4,10.3-8.5c4.1-4.1,7.7-8.7,11.9-12.8c8.9-8.5,17.9-16.8,27-25.1
				c4.9-4.4,10-8.7,15.1-12.9c12.9-10.8,23.6-23.1,28.7-39.5c1.8-5.8,5.2-10.5,10.4-14c12.3-8.3,25.8-14.2,39.4-19.6
				c22.6-8.9,45.6-16.9,68.4-25.4c6-2.3,11.8-5,17.6-7.9c2.5-1.3,5.1-3.1,5.2-6.8c-1.2-0.9-2.5-1.7-3.6-2.7
				c-1.2-1.1-2.5-2.2-3.5-3.4c-3.6-4.1-2.6-9.5,2.3-11.9c1.9-0.9,4-1.7,6-2.1c4.7-0.9,9.4-1.4,14.1-2.1c11.1-1.6,22.1-3.1,33.1-5
				c6.6-1.2,13.2-2.9,19.5-5.1c5.2-1.8,5.9-4.1,4.5-9.3c-0.9-3.3-1.9-6.6-2.5-10c-0.8-4.6,1.1-7.9,5.4-9.2c2.3-0.7,4.8-1.1,7.3-1.3
				c8.3-0.6,16.4,1,24.6,2.5c10,1.9,19.1,5.8,28.2,10.3c15.1,7.7,30.5,14.9,44.7,24.3C840,333.1,842.9,334.6,845.8,336.2z
				 M344.4,452.3c0-0.9-0.2-1.4,0-1.8c3.1-7.1,5.4-14.4,6.9-22c1.2-6,1.1-12.2,2.2-18.1c1.4-7.9-1-15.6-0.5-23.4
				c0.2-3.4-0.5-6.9-1-10.3c-0.9-7-1.9-14-2.8-21c-1.1-8.2-2.1-16.3-3.3-24.5c-1.5-10.3-3.3-20.6-1.7-31c0.9-5.7,2.7-11.3,6.3-15.9
				c2.7-3.4,6.9-3.2,9.1,0.5c1,1.7,1.6,3.6,2.2,5.5c3.2,10.1,3.5,20.6,3.8,30.9c0.4,18.3-0.5,36.6-0.8,54.9c0,0.3,0.1,0.7-0.1,1
				c-2.3,6.3-0.3,12.5,0.3,18.7c0.2,1.9,1.5,4.3,3,5.2c2.2,1.3,3.9-1,5.4-2.5c0.9-1,1.7-2,2.3-3.2c3.7-8,5.4-16.5,6.6-25.2
				c0.9-6.7,2.1-13.3,2.9-20c0.3-2.7,1.2-4.4,4-5.5c1.4-0.6,2.8-2.5,3.3-4.1c1.2-3.7,2.7-7.6,2.7-11.4c0.1-9.4-0.1-18.9-0.9-28.3
				c-0.9-10.7-2.6-21.2-4-31.9c-1.2-9.1-3.4-18.2-3.7-27.4c-0.4-13.4-0.8-26.8,1.6-40.2c2.8-15.9,5.8-31.7,11.5-46.8
				c5.5-14.6,12.5-28.4,22.4-40.4c3.4-4.1,7-8.5,12.7-9.9c2.5,0.9,3.5,2.6,3.2,5c-0.3,2.6-0.5,5.2-1,7.8c-2.8,14-5.8,27.9-8.5,42
				c-2,10.6-4.6,21.3-5.3,32.1c-1.1,18.6-0.4,37.2,1.9,55.7c1.4,11.4,3,22.8,4.9,34.2c2.2,12.8,4.4,25.6,7.7,38.2
				c5,18.9,10.9,37.6,16.5,56.6c4.2-3.4,8.5-7,13-10.4c14.3-11.1,28.5-22.1,42.8-33.1c12.1-9.3,24.1-18.7,36.3-27.8
				c14.8-10.9,30.6-20.2,46.5-29.4c14.9-8.6,30.2-16.3,46.5-22.2c21.6-7.8,43.8-13.3,66.1-18.6c16.5-3.9,33.2-5.9,50-6.7
				c8.5-0.4,17.1,1.1,25.7,1.7c7.9,0.5,15.7,1.7,23.3,4.1c23.9,7.6,47.4,16.4,69.3,28.8c1,0.6,2.2,0.7,3.9,1.2c-0.5-2.3-1-3.7-1-5.1
				c-0.3-6.7-2.3-13-5-19c-6-13.2-12.9-25.9-21.1-38c-6.9-10.2-13.5-20.8-17.1-32.8c-0.6-1.9-0.6-4-1-5.9c0.4-0.2,0.8-0.4,1.2-0.6
				c1.2,1,2.6,1.8,3.6,2.9c5.3,5.9,10.8,11.7,15.8,17.8c9.6,11.8,19,23.7,26.7,36.8c6.6,11.4,12.9,23,19.3,34.5
				c7,12.4,13.5,24.9,17.2,38.7c1.9,7.2,7.9,10.6,12.7,15.9c1.7-13.6,0.7-23.8-6.2-36.9c-12.5-23.6-26.7-46-43.5-66.8
				c-14.9-18.5-29.1-37.6-41.8-57.9c-5-8-10.5-15.6-16.5-22.9c-7.5-9.3-18-15.2-28.2-20.9c-8.7-4.9-18.3-8.4-27.7-12
				c-14.9-5.7-30-11.1-45-16.4c-2-0.7-4.2-0.8-6.3-0.8c-5.3,0.2-10.6,1-15.8,0.9c-10.3-0.2-20.5,0.2-30.2,4.2
				c-6.2,2.6-12.4,5.5-18.6,8.2c-13.3,5.9-26.6,9-40.1,0c-4.7-3.1-10.4-2.3-15.4-0.8c-7.9,2.4-15.5,5.6-23.1,8.6
				c-8.3,3.3-16.6,6.4-25.9,5.8c-7.2-0.4-12.9-5.7-11-13.7c1.5-6.6,5.4-11.9,10.3-16.5c6-5.7,13.7-7.7,21.5-9.5
				c6.4-1.5,12.6-3.5,18.4-6.8c4.1-2.3,6.6-5.4,5.7-10.4c-0.9-5.5-2.3-11-2.7-16.5c-0.4-5.3-3.6-7.5-8-8.7c-2-0.6-4.2-0.7-6.3-1
				c-2-0.3-3.9-0.4-5.9-0.8c-9.9-1.8-19.5-4.5-29.9-2.4c-15.9,3.3-29,11.1-39.7,22.9c-8.8,9.7-17,19.9-25.2,30.2
				c-7.2,9-16.3,15.4-26.1,21.3c-10.5,6.2-21.7,11.6-29.7,21.3c-1.5,1.8-2.9,3.6-4.4,5.4c-11.6,13.6-21.2,28.6-29.7,44.4
				c-6,11.1-11.4,22.4-17.3,33.5c-10.7,20.1-17.8,41.5-21.8,63.9c-1.2,6.6-2.3,13.3-3.2,20c-1,7.2-2,14.4-2.7,21.6
				c-0.5,5.1-0.7,10.2-1.1,15.4c-0.7,9.9-1.6,19.8-1.9,29.7c-0.2,6.9,0.9,13.9,0.8,20.9c-0.1,14.8-0.5,29.6-1,44.4
				c-0.2,8.1-0.7,16.3-1,24.4c0,0.5-0.1,1-0.1,1.5c-0.9,9-1.9,18-2.8,27c-0.1,1.3,0.2,2.6,0.3,3.9c0.8,0,1.4,0.2,1.8,0
				c4.7-2.4,9-5.5,14.5-6.2c0.7-0.1,1.6-0.7,2-1.4c2.1-3.7,5.9-5.2,9.2-7.2c21.5-13,41.2-28.4,60.1-44.8c5.6-4.9,11.4-9.6,17.2-14.4
				c5-4.1,10-8.1,15.2-12.4c0-0.9,0.3-2,0.2-3.2c-0.7-8.2-3-16.1-5.7-23.9c-2.6-7.4-5.2-14.9-7.2-22.5c-3-11.4-5.6-23-8-34.6
				c-1.8-8.7-2.9-17.5-4.1-26.3c-0.8-5.5-1.4-11.1-2-16.7c-1-7.8-2.7-15.7-2.8-23.5c-0.2-14.6-1.5-29.3,0.8-43.8
				c1.4-8.9,2.8-17.8,4.5-26.7c2.6-13,5.6-26,8.4-39c0.8-4,1.8-8,0.4-12.6c-1.2,0.5-2.4,0.6-3.1,1.3c-4.3,4.2-9,8-12.4,12.8
				c-8,11.1-13.7,23.5-18.4,36.4c-3.9,10.8-6.6,22-8.6,33.3c-1.9,10.5-3,21.2-4,31.8c-0.4,4.2,0.5,8.6,0.9,12.9
				c0.7,8.4,1,16.8,2.1,25.2c1.9,13.5,4.8,26.8,6,40.5c1,11.3,1.5,22.5-0.2,33.7c-0.8,5.4-3,10.1-7.6,13.4c-1,0.7-2,1.9-2.2,3
				c-0.9,5.4-1.9,10.8-2,16.2c-0.3,8.7-2.6,16.9-5.3,25c-1.3,3.9-3.3,7.6-6.6,10.4c-4.5,3.8-8.2,3.1-9.7-2.5c-1.1-4.3-2-8.7-2-13.1
				c0-9.8,0.9-19.6,0.8-29.4c-0.1-16.1-0.4-32.3-1-48.4c-0.2-5.9-1.2-11.8-2.3-17.6c-0.5-2.4-1.1-5.3-4.4-6
				c-4.2,3.3-7.2,9.4-7.6,15.9c-0.6,11.5,0.5,22.8,2.5,34.1c1.3,7.6,2.4,15.3,3.4,22.9c0.9,6.5,1.5,13.1,2.2,19.7
				c0.9,8.5,2.1,17,2.7,25.6c0.3,3.8-0.8,7.6-0.8,11.4c-0.1,10.4-2.8,20.2-6.1,29.9C349.6,447.9,347.5,450.3,344.4,452.3z
				 M429,1073.8c1.5,1.6,2.9,3,4.3,4.5c11.5,12.2,23.4,24.1,34.4,36.8c6.6,7.5,14,13.6,22.8,18.1c15.3,7.9,30.7,15.4,46.8,21.4
				c14.4,5.4,29.3,8.5,44.4,10.7c7.3,1,14.8,1.5,22.2,2.1c7.6,0.7,15.1,1.7,22.7,1.9c16.1,0.3,32.2-0.3,48.3-1.7
				c11.5-1,23-1.8,34.4-4.2c24.3-5.2,48-12.2,69.6-24.9c14-8.2,27-17.6,37.9-29.6c10.9-11.9,21.3-24.1,31.7-36.5
				c10.8-12.8,21.6-25.6,31.7-38.9c8.1-10.6,15.6-21.8,22.8-33c7.4-11.5,11-24.6,13-38c0.6-3.7,2.4-7.6,0.2-12.3
				c-3.3,0.9-6.7,1.4-9.8,2.7c-5,2-9.8,4.7-14.9,6.6c-6.4,2.4-12.9,5.1-19.6,6.2c-8.8,1.4-16.8-2.2-24.3-6.7
				c-1.8-1.1-3.7-2.4-5.7-2.9c-9.4-2.5-16.4-7.9-20.5-16.6c-2.6-5.5-4.3-11.4-6.3-17.1c-0.9-2.6-1.6-5.4-2.7-9.2
				c-2.1,2.4-3.5,3.8-4.6,5.5c-9.3,13.4-23,18.8-38.5,19.1c-12.4,0.3-24.8,2.1-37.2,0.3c-5.5-0.8-11.2-0.7-16.8-1.1
				c-3.5-0.3-6.9-0.5-10.3-1.2c-8.2-1.5-16.4-3.1-24.6-4.8c-19.5-3.9-38.8-8.7-57.7-14.8c-12.6-4-24.9-8.9-35.8-16.5
				c-6.6-4.6-12.7-10.1-19.2-14.8c-13.4-9.8-26.6-19.8-38.1-31.9c-6-6.3-12.6-11.9-18.2-18.5c-9.1-10.6-16.7-22.2-23.6-34.3
				c-1.7-2.9-3.5-5.8-5.2-8.7c-0.4,0.1-0.8,0.3-1.1,0.4c1.3,5.9,2.3,11.8,3.9,17.7c1.5,5.5,3.6,10.9,5.2,16.4
				c1.2,4,2.7,7.7,5.9,10.7c2.1,2,3.6,4.7,5.2,7.2c5.7,9.4,5.9,20.1,7.2,30.5c1,8.8,1.1,17.8,2.2,26.7c1.1,9.2,4,18,9.6,25.6
				c6.4,8.8,13.3,17.3,19.2,26.4c4.5,6.8,7.2,14.3,7.2,22.8c0,6.4-2.4,10.8-7.9,13.7c-10.3,5.5-17.1,14.3-22.2,24.6
				c-3.4,6.8-7,13.5-10.6,20.2c-2.8,5.3-6.6,9.5-12.3,11.8c-6.1,2.5-12.4,4.7-18.3,7.6c-7,3.4-13,3.3-18.1-3.8
				C447.4,1056.9,436.2,1062.6,429,1073.8z M313,755.9c6.7,0.1,10.6,2.8,13.2,7.5c4.9,8.9,5.7,18.8,5.2,28.4
				c-0.5,9.9-3.5,19.7-5.3,29.5c-1.8,9.7-3.8,19.4-3.4,29.4c0.5,10.4,9.3,16.7,19.2,13.7c5.2-1.6,9.9-4.2,13.4-8.1
				c4.6-5.1,8.5-10.9,12.9-16.2c1.5-1.8,2.2-5,5.3-4.6c3.6,0.5,3.3,4.1,4.2,6.6c0.1,0.1,0,0.3,0,0.5c1.3,8.9,2.5,17.9,4,26.8
				c1.8,10.6,5.3,20.8,11.5,29.7c2,2.9,4.9,5.9,8.1,7c4.2,1.5,9,0.5,12.4-3.1c2.7-2.8,5.5-5.9,7.1-9.4c7.2-15.4,7.6-31.9,5.8-48.4
				c-0.9-9-2.4-17.9-3.6-26.9c-1.1-8-2.4-16-3.4-23.9c-0.9-7-1.9-14-2.5-21.1c-0.7-8.6,1.1-17,2.8-25.5c3.4-16.3,8-32.2,15.7-47
				c5.1-10,9.4-20.3,12.8-31c3-9.5,3.2-19.3,2.4-29c-0.6-8.2-2.8-16.2-3.7-24.3c-1.3-12.3-4.8-24.2-5.4-36.6
				c-0.5-8.9-1.3-17.8,0.2-26.7c0.5-2.8,0.5-5.7,0.8-8.7c-0.6,0.2-1,0.2-1.3,0.4c-13.9,8.7-27.6,17.6-42.6,24.3
				c-1,0.5-1.9,1.4-2.8,2c-4.6,2.9-8.9,6.5-13.9,8.4c-5.2,1.9-9.3,4.8-13.1,8.6c-6.3,6.4-13.5,11.7-21.3,16.3
				c-1.8,1.1-3.7,2.5-4.8,4.2c-4.2,6.1-8.2,12.4-10.8,19.4c-3.9,10.4-5.4,20.6-0.6,31.2c5.1,11.2,10.9,22.3,11.4,35
				c0.3,6.8,0.5,13.6,0.6,20.4c0,0.9-0.6,1.9-1,2.8c-1.1,2.2-2.4,4.3-3.2,6.6c-3.8,10.3-11.6,17.6-19.4,24.8
				C317.8,750.6,315.8,752.9,313,755.9z M718.7,648.7c-1.3-9.3-3.1-20.7-4.3-32.2c-1.1-10.3-6.7-17.6-14.9-22.8
				c-7.6-4.8-15.8-8.7-23.9-12.7c-17.8-8.7-36.8-13.4-56.4-15c-15.4-1.3-30.7,0.1-45.4,5.4c-13.7,5-26.4,12.1-38.9,19.3
				c-5.1,3-9.8,6.8-14.6,10.3c-2.6,1.9-2.8,4.4-1.8,7.5c2.2,6.8,6.2,12.4,10.8,17.5c11.3,12.3,25,21.6,39.8,29.2
				c5.6,2.9,11.4,5.1,17,8.1c11,5.9,19.6,14.1,24.1,26c0.8,2.3,2.5,3.4,4.2,4.6c8.5,6.2,16.9,12.5,25.5,18.6
				c6.2,4.4,12.3,9.1,19,12.4c9.1,4.6,18.9,5.3,27.8-0.8c3.9-2.7,7.3-6.3,10.1-10.1c6.6-8.9,12.7-18.2,16.5-28.6
				C717.2,674.3,717.4,662.5,718.7,648.7z M916.1,718.8c-1.4,4.1-2.4,7.5-3.7,10.8c-5.9,15.5-11.4,31.2-17.9,46.5
				c-5.8,13.7-11.9,27.3-19.2,40.2c-10.5,18.7-22.1,36.8-33.5,55c-5.2,8.4-11,16.5-16.5,24.7c-1.5,2.2-0.7,3.8,0.6,5.7
				c4.6,6.7,10.9,11.4,17.7,15.6c15.5,9.5,32.1,12.2,49.2,5.5c7.3-2.8,13.9-7.3,20.7-11.4c7.9-4.8,15.5-10.2,24.8-11.9
				c4.6-0.8,8.4-2.5,11.5-6c1.3-1.5,3.5-2.5,5.5-3.1c2.6-0.8,4.1-2.1,4-4.9c-0.1-1.6-0.1-3.3-0.7-4.8c-2.8-7.6-5.5-15.1-8.7-22.5
				c-3-7.1-6.9-13.9-9.8-21c-1.9-4.7-3-9.7-3.6-14.7c-0.5-4.5,2.4-6.1,6.7-4.6c3.8,1.4,6.3,4.1,8.7,7.2c5.8,7.6,11.4,15.4,17.4,22.8
				c3.8,4.6,8.3,8.5,14.2,10.3c5-3.6,7-8.3,6.9-14.2c-0.1-4-0.2-8,0-12c0.3-3.7,0.4-8,4.1-10.1c2.3-1.4,5.2-2,8-2.5
				c6.9-1.3,13.9-2.3,21.1-3.4c0.8-1.9,1.5-3.6,2.2-5.3c-5.2-1.4-10.2-2.2-14.7-4c-8.1-3.3-13.6-9.9-18.6-16.7
				c-4.2-5.7-7.8-11.8-11.8-17.8c-5.9-1.1-10.6,1.5-14.7,5.4c-6.6,6.3-9.7,5.1-16.4,2.1c-1.2-0.5-2.3-1.3-3.3-2.2
				c-3.7-3.3-7.4-6.7-11.1-10c-5.5-4.9-9-11-10.9-18C921.4,739.5,918.9,729.7,916.1,718.8z M444.7,542.9c-0.3,6.3-0.8,12.4-0.8,18.5
				c0,6.1,0.5,12.2,1,18.3c0.4,4.7,1.1,9.5,1.8,14.2c1,6.7,2.2,13.3,3.2,20c1.1,7.5,2,15,3.2,22.5c1.5,9.7,1.4,19.4-0.9,29
				c-2.3,9.5-5.9,18.4-10.2,27.2c-6,12.3-12.3,24.4-15.3,37.9c-2.3,10.4-5.2,20.7-6.6,31.3c-1.3,10.2,0.7,20.4,2.3,30.6
				c1.6,10.2,3,20.5,4.5,30.7c0.6,4.2,1.3,8.5,1.7,12.7c0.8,7,2,14.1,2.1,21.2c0.1,6.2-1.1,12.5-1.9,18.7
				c-1.1,8.6-3.5,16.8-8.7,23.8c-2.1,2.9-4.8,5.5-7.8,7.5c-4.6,2.9-12.1,3.1-16.7-1.9c-11-12.1-14.7-26.9-17.3-42.3
				c-1.1-7-2-14-3.1-21c-0.2-1.3-1-2.6-1.9-4.5c-2.1,2.9-3.8,5.1-5.4,7.4c-4.6,6.7-9.3,13.3-16.1,18c-5,3.4-10.3,5.9-16.6,5.4
				c-6-0.5-11-4.5-13.2-10.3c-2.4-6.6-2.2-13.2-0.9-20c2.3-11.9,4.3-23.9,6.3-35.9c1.8-10.6,1.7-21.2-1.7-31.4
				c-2.6-7.6-5-12.2-15.2-10.2c-7,8.2-13.1,16.1-19.9,23.2c-5.8,6-11.7,11.7-15.8,19c-3.2,5.7-6.1,11.6-7.7,16.9
				c3.4,4.2,6.6,7.6,9.1,11.4c6.1,9.1,9.6,19.5,13.7,29.6c3,7.4,6.3,14.4,11.3,20.8c6.4,8,12.1,16.5,18.3,24.7
				c1.3,1.7,3,3.1,4.6,4.4c7.3,5.8,15.8,5.7,24,3.4c9.2-2.6,18-6.5,24.9-13.7c3-3.1,5.6-3,8.5,0.3c2.5,2.9,4.5,6.1,6.6,9.3
				c1.8,2.8,3.2,5.7,4.9,8.6c2.3,4,5.7,5.7,10.4,5.1c4.4-0.6,8.1-2.5,11.6-5.2c7.2-5.7,11.8-13.3,14.8-21.8
				c5-13.8,9.4-27.8,8.6-42.8c-0.2-4.3-0.5-8.6-1-12.8c-0.8-6.2-1.8-12.4-2.8-18.5c-1.4-8.4-3.4-16.8-4-25.3
				c-0.9-12.1-0.3-24.2,2.2-36.1c2.5-12.2,6.1-24.1,10.5-35.9c6.5-17.2,12.1-34.7,16.6-52.6c2.7-10.8,5.1-21.5,4.6-32.7
				c-0.5-11.3-0.6-22.6-1.1-33.9c-0.2-4.4-0.8-8.9-1.6-13.2c-2.4-12.2-4.8-24.3-4.3-36.8c0.3-7.8,0.7-15.7,1.1-23.9
				C453.8,535.5,449.3,539.2,444.7,542.9z M865.3,706.9c0.6,5,0.6,9.6,1.7,13.8c2.2,8.7,9.1,10.7,15.9,6.5c6.5-4,11.2-9.5,15.6-15.7
				c11.9-16.9,19.3-35.4,21.8-55.9c0.5-3.8,1-7.5,1.2-11.3c0.3-5.5,0.8-10.9,0.6-16.4c-0.3-7.1-0.8-14.2-1.9-21.2
				c-1.3-8.1-3-16.1-5-24c-1.7-6.6-6.5-11.5-10.9-16.3c-5.3-5.8-11.6-9.9-20.1-8.7c-0.8,0.1-1.7,0-2.5-0.1
				c-6.9-0.8-12.7,1.7-17.5,6.5c-2.5,2.6-4.8,5.5-6.8,8.5c-2.8,4-3.3,8.4-1.7,13.2c3.2,9.1,6.2,18.3,9.3,27.5
				c3.1,9.6,5,19.4,5.9,29.4c1.3,15-1.8,29.7-3.1,44.5C867.1,694,866.1,700.7,865.3,706.9z M329.5,476.2c0.4,3.6,1.1,7,1.1,10.4
				c0,7.3,2,14.1,5.5,20.4c5.4,9.7,9.4,20,12.5,30.7c2.6,9.2,5.3,18.3,8,27.4c1.2,3.9,2.4,7.9,6.4,10.4c2.8-1.6,5.4-3.6,8.3-4.6
				c9.5-3.4,15.6-11,22.8-17.2c2.2-1.9,4.1-4.4,2.2-7.9c-1-1.8-1.1-4.1-1.8-6.1c-4-11.2-6.7-22.8-8.1-34.5
				c-1.9-15.1,2.7-29.1,10.4-42c8.2-13.5,18.5-25.3,29.5-36.6c6.2-6.4,12-13.3,16.8-20.8c3-4.7,5.1-9.8,5.6-15.5
				c-3.8,2.3-7.1,4.9-10.4,7.6c-12.3,10.3-24.6,20.6-36.9,30.9c-15.4,12.9-32.1,24-49.4,34C344.6,467,337.3,471.5,329.5,476.2z
				 M867.8,637.9c0.2-1.1,0.4-1.8,0.4-2.6c0-0.8,0.1-1.7-0.1-2.5c-3.8-13.9-7-28-12.9-41.3c-3.5-7.9-3.3-15.8,2.3-22.9
				c1.4-1.8,3.4-3.3,4.8-5.1c6.2-7.8,14.7-9.2,23.8-8.1c4.6,0.6,9.2,1.4,13.3,4.6c6.1,4.8,10.7,10.7,14.8,17.2
				c1.4,2.2,2.7,4.4,4,6.6c0.4-0.1,0.7-0.2,1.1-0.3c-0.4-12.4-0.8-24.7-1.2-37.1c-10,1.6-19.2-8.5-21.5-23.6
				c-8.3,4.5-17.4,5.1-26.4,5.9c-9.3,0.9-18-1.9-26.3-6c3-0.1,5.8,0.3,8.7,0.7c2.9,0.4,5.8,0.9,8.8,1.3c5.7,0.6,11.5,1.5,17.2,1.6
				c7.3,0.1,12.1-3.7,14.3-10.5c2.2-6.7,4.4-13.4,6.7-20.4c-2.6-3.3-13.5-6.2-19.2-4.7c6.1,5.4,6.4,6,6.3,13.5
				c-0.2,8.8-7.4,15.8-16.3,15.7c-1.5,0-3,0.1-4.5,0c-10.1-0.8-18.7-10.8-17.6-18.8c0.4-3.1,2.2-6,3.4-9.2c-0.9-1.4-2.2-0.7-3.3,0.1
				c-4.3,3.1-8.6,6.2-12.7,9.6c-1.3,1.1-2.2,2.7-3,4.3c-2.2,4.5-0.9,8.7,1.6,12.6c1.6,2.6,1.6,4.7-0.5,7.1c-2.3,2.7-4.1,5.8-5.3,9.3
				c-1.4,4.4-4.6,7.5-8.9,9.1c-1.8-0.6-3.2-1.1-5.2-1.8c0.7,1.8,1,2.9,1.5,3.9c4.2,9.4,8.2,18.8,12.8,28c4.2,8.6,8.9,17,13.6,25.3
				c3.2,5.6,6.8,10.9,10.2,16.4c1.5,2.5,2.8,5.1,6.2,5.8c1.3,0.3,2.5,2.1,3.2,3.4C864,629,865.7,633.3,867.8,637.9z M675.2,477.7
				c6.1,3.8,12.2,7.6,19.2,12c-0.7-2.1-1.1-3-1.2-3.9c-0.5-3.8,0.7-5.2,4.6-4.5c4.9,0.9,9.7,0.7,14.7,0.1c3.9-0.5,7.4-1.1,10.4-3.9
				c-0.9-1-1.7-1.7-2.4-2.7c-7.7-10.8-18-18.2-30.7-21.4c-18-4.6-35.7-10.7-54.5-11.6c-8.4-0.4-17-1.5-25.3-0.7
				c-11.7,1.1-22.6,5.4-33.3,10.3c-12.5,5.8-23.4,14.1-34.3,22.4c-3.4,2.6-6.8,5-11.6,4.7c-1.9,6.4-3.8,12.7-5.5,19
				c-0.5,2-0.6,4.3-0.6,6.4c0.2,4,0.7,7.9,0.9,11.9c0.2,4.9,1.3,6.7,6,7.6c3.7,0.7,7.5,0.6,11.3,1.1c7.3,0.9,14.8,1.3,21.9,3.3
				c7,1.9,13.7,4.4,21.1,2.8c4.6-1,8.9-2.4,13.2-4.5c-4.1-2-8.2-3.6-12.3-5.2c-0.8-0.3-1.6-0.7-2.3-0.7c-6.8,0.5-12.6-2.1-18.3-5.4
				c0-2.6,0-4.9,0-7.2c0-1.9-0.7-2.9-2.6-3.6c-6-2.4-6.6-3.9-4.5-10.3c3.3-9.9,8.9-18.2,17.6-24.5c12.8-9.3,26.7-14.7,42.6-14.7
				c3.1,0,6.2,0.6,9.3,1c9.7,1.2,19.2,3.4,27.8,8c6.9,3.7,13.3,8.2,19.9,12.3C675.7,476.6,675.5,477.2,675.2,477.7z M927.8,499.4
				c-0.4,0.2-0.8,0.3-0.9,0.5c-0.4,0.7-0.8,1.4-1.1,2.2c-6,12.8-3.9,26.2-2.7,39.6c0.6,6.9,1.2,13.8,1.6,20.8
				c0.1,1.6-0.8,3.4-0.4,4.8c2.4,7.7,1.9,15.8,2.3,23.6c0.7,13.2,0.5,26.5,1.9,39.7c1.1,10.5,0.4,21.3-1,31.8
				c-1.6,12-5.1,23.7-7.2,35.7c-1.1,5.9-1.8,12.2-1.2,18.1c0.9,8.6,2.9,17.2,5.1,25.6c1.5,5.8,2.9,11.7,6.4,17
				c3.6,5.5,8.1,10,13.3,13.9c3.2,2.3,6.5,4.4,10,6.3c2.4,1.3,4.8,1.1,7.2-0.9c5.2-4.1,10.2-8.8,17.9-9.1c-1.2-2-2-3.4-2.9-4.8
				c-12-17.6-19.5-37.1-24.7-57.5c-2.2-8.7-3.7-17.7-5.1-26.7c-1.6-9.9-2.7-19.9-4-29.9c-0.4-3.3-0.6-6.6-1.1-9.9
				c-1.2-8.3-2.6-16.6-3.9-24.9c-1.4-9.1-3.1-18.2-4.1-27.4c-0.7-6.7-0.9-13.6-0.7-20.4c0.3-8.1,1.4-16.2,1.8-24.2
				c0.3-6.1-0.7-12.3,2.9-17.7c-5.3-3.4-7.9-7.8-8-13.9C929.1,507.6,928.3,503.5,927.8,499.4z M460,1048.5c5,5.4,5.7,5.7,11.7,3.2
				c6.7-2.8,13.3-5.8,20-8.6c5.4-2.3,9.5-5.9,12.2-11.1c3.5-6.7,6.7-13.6,10.5-20.2c4.3-7.4,8.4-15.1,15.8-20.1
				c2.9-1.9,5.8-3.8,8.9-5.4c5-2.7,6.9-8.3,6-13.1c-0.8-4.5-2.5-8.8-4.2-13.1c-3.8-9.1-9.9-16.7-16.1-24.2
				c-7.3-9-13.3-18.7-15.1-30.2c-1.7-10.6-2.2-21.3-3.3-32c-0.6-6.2-1-12.5-2.2-18.6c-1.4-7.3-5.1-13.7-10.7-19.2
				c-0.2,0.8-0.6,1.4-0.4,1.7c2.1,5.8,2.9,11.8,3,18c0.2,8.4,1.5,16.8,1.8,25.2c0.3,9.2,0.1,18.3,0.1,27.5c0,1.5-0.2,3-0.3,4.5
				c-0.4,6.9-2.3,13.5-1.1,20.6c1.3,7.8,1,15.9,1.4,23.8c0.2,2.8,0.5,5.6,0.8,8.4c0.1,1.5,0.5,3.1,0.1,4.5
				c-2.4,10.8-5,21.5-10.9,31.1c-0.9,1.5-1.6,3.2-2,4.9c-3,12-7.4,23.3-16.1,32.4C466.6,1042,463.4,1045.1,460,1048.5z M587.7,24.5
				c1.5,6.3,3,11.3,3.9,16.5c1.1,5.9,0,11.3-5.4,15c-2.3,1.5-4.7,3.1-7.4,4c-7.3,2.5-14.7,4.6-22.1,6.8c-10,2.9-16.3,9.5-20.2,18.9
				c-2.5,6,0.5,11,7,11.6c6.5,0.7,12.8-0.3,18.9-2.8c8.5-3.5,17-7.2,25.7-10.2c9.2-3.3,18.3-3.4,27,2.5c5.6,3.8,12.3,3.1,18.8,1.8
				c10.5-2,19.8-7.3,29.4-11.5c11.3-4.8,22.9-7.3,35.2-5c1.8,0.3,3.7,0,5.6,0c0.1-0.4,0.1-0.8,0.2-1.3c-1.7-0.7-3.4-1.7-5.2-2.2
				c-9.8-2.7-19.7-5.4-29.6-7.9c-25.5-6.3-49.6-16-71.5-30.6C595,28.2,591.7,26.7,587.7,24.5z M819.8,684.3c4.3-2.9,8.6-6,13-8.9
				c3.8-2.6,8.1-4.6,11.6-7.5c7.2-6.1,13.7-13,18.3-21.4c0.9-1.6,1.2-3.1,0.8-5.1c-1.6-9.6-7.2-15.2-16.3-17.9
				c-1.9-0.6-3.9-0.9-5.7-1.7c-7-3.1-13.6-2.1-19.8,1.7c-8.9,5.4-17.8,10.8-26.5,16.5c-6.4,4.2-13.4,4.5-20.6,3.5
				c-7.3-1-14.6-2.5-21.9-3.6c-5.4-0.8-10.1,0.6-14.1,4.5c-7,6.9-8,15.1-6.6,24.2c0.7,4.7,4,7.6,7.1,10.4c4.5,4.1,9.6,8,15.6,9
				c13.9,2.4,26.9-7.5,32.5-16.8c0.7-1.1,0.5-3.3-0.2-4.4c-0.4-0.7-2.7-0.7-3.9-0.2c-3.7,1.3-7.3,3-11,4.4c-4,1.6-8.8,1.1-10.7-1.3
				c-4.7-6.1-1.9-15.1,3.4-18.1c16.9-9.5,38.3,2.1,42.9,16.7c1.1,3.4,2.8,6.7,4.3,10C813.7,681.4,816.1,683.5,819.8,684.3z
				 M606.2,688.5c-3-8.1-8.2-13.4-14.1-17.5c-5.6-3.9-11.9-7-18.2-9.8c-11.2-5.1-21.4-11.7-31.1-19.1c-7.5-5.8-14.7-12-19.9-20
				c-3.1-4.7-5.3-10-8.1-15.2c-0.8,0.7-2.1,1.4-2.6,2.5c-2.4,4.5-4.8,9-6.8,13.6c-1,2.5-1.8,5.5-1.5,8.1c0.9,7.5,3.8,14.3,9.1,19.9
				c8.3,8.8,18.7,13.9,30.1,17.3c16.5,5,33,10,49.4,15.1C596.9,684.6,601.1,686.5,606.2,688.5z M321.7,483.3
				c-4.2,2.8-6.3,5.1-8.8,9.9c-4.5,8.8-4.1,17.9-1.6,27c5,18.7,10.2,37.4,15.4,56.1c1.3,4.6,3,9.1,5,13.4c1.6,3.5,3.7,7,7.8,8.1
				c5.6-5.9,11-11.7,16.2-17.2c-2.2-4.1-4.8-7.9-6.3-12c-2.7-7.1-5.1-14.3-7-21.6c-3.5-13-8.4-25.3-14.4-37.2
				c-2.3-4.5-3.5-9.6-4.7-14.5C322.4,491.5,322.2,487.4,321.7,483.3z M721.7,482.7c-9,3.2-17.8,1.4-26.6-0.4
				c-0.9,3.4,2.2,6.4-0.3,9.6c-1.4-0.6-2.8-1.1-4-1.9c-10.7-6.4-21.5-12.8-32.2-19.3c-11.9-7.2-24.9-8.3-38.3-6.4
				c-12.5,1.7-22.9,8-32.3,16c-3.2,2.7-6.4,5.5-8.3,9.6c2.3-0.7,4.9-1.3,6.4-2.9c8.2-9.2,18.6-13.5,30.6-13.7
				c9.3-0.1,18.6,0.4,27.9,1c3.2,0.2,6.6,0.9,9.6,2.1c15.2,6.2,29.5,14,42.1,24.7c7.2,6.1,8.2,11.3,3.5,20.1
				c4.5,2.2,8.9,3.8,14.1,2.5c4.5-1.1,6.6-4.8,9.2-7.9c4.2-5.1,6-11,5.1-17.6c-0.7-4.9-1.4-9.8-2.2-14.7c0-0.3-0.4-0.5-0.8-0.9
				c-1.1,0.7-2.2,1.3-3.3,2C721.8,483.8,721.8,483.4,721.7,482.7z M929.2,390.3c1.2,5.8,2.8,11.2,3.4,16.7
				c0.8,7.2,0.8,14.6,1.1,21.9c0.4,11.9,0.8,23.9,1,35.8c0.1,4.1-0.8,8.2-0.9,12.3c-0.1,11.3,0,22.7-0.1,34c0,3.2,1.3,5.7,4.3,7.4
				c2.6-1.8,4.6-3.8,5.3-7c2.9-12.5,6.1-24.9,8.9-37.4c1.1-4.8,1.7-9.8,1.9-14.7c0.1-5.3-0.4-10.6-0.9-15.9
				c-0.4-4.1-0.8-8.2-1.9-12.1c-2.3-8.2-5-16.4-7.9-24.4c-1.4-3.8-3.4-7.5-5.7-10.9C935.7,393.2,933,390.9,929.2,390.3z
				 M888.9,465.1c-1.7-1.2-2.3-1.7-3.1-2.1c-1-0.5-2.1-0.9-3.2-1.2c-12.6-2.6-24.7-1.4-35.5,6.2c-9.6,6.7-19.1,13.6-28.6,20.5
				c-8,5.7-11.1,13.7-10.7,23.2c0.2,4.4,0.9,8.9,2.1,13.1c1.1,4.3,2.7,8.5,4.8,12.4c2.3,4.2,6.5,4.3,9.2,0.5
				c2.1-2.9,3.5-6.2,5.2-9.4c-0.9-0.7-1.3-1-1.7-1.2c-6.2-2.8-7.5-5.3-5.1-11.2c1.7-4.1,3.8-8,5.7-12c-4.1-5.3-4-7.1,0.6-11.9
				c7.3-7.6,15.8-13.6,25.3-18.1c8-3.8,16.5-6.4,25.6-6.8C882.4,467,885.2,465.9,888.9,465.1z M964.3,462.5
				c4-22.2,4.7-44.3-1.2-66.2c-5.7-21.4-12.1-42.7-25.7-60.9c-0.5,1-0.7,1.8-0.6,2.6c0.6,6.2,1,12.5,2,18.6
				c1.5,8.9,3.2,17.8,5.6,26.5c2.1,7.9,5.5,15.5,7.9,23.3c2.4,7.7,4.3,15.5,6.3,23.3c2.5,10.2,6.1,20.3,5.3,31.1
				C963.8,461.4,964.1,461.9,964.3,462.5z M961.2,885.6c11.1-4.3,19.5-11.2,23.1-23.3c-6.4-2.4-11.9-5.8-16-10.9
				c-5.6-7.1-10.8-14.5-16.2-21.8c-2-2.6-4.1-5.2-6.4-7.6c-1.7-1.8-3.9-3-6.6-1.7c-1.2,5.1,0.2,9.8,1.9,14.1
				c3.4,8.4,7.4,16.5,11.2,24.7C956.3,867.6,959.7,876.3,961.2,885.6z M487.7,372.6c-0.2,0.1-0.4,0.1-0.6,0.2
				c0.9,4,1.4,8.1,2.8,11.9c4.3,11.2,17,16.1,27.6,10.7c2.8-1.4,5.5-3.1,7.7-5.3c4.1-4.1,6.1-9.3,5.3-15.2
				c-1.5-11.5-8.4-18.2-19.2-21.4c-6.5-1.9-12.2,0.1-18,3.1c-4.1,2.2-3.9,6.2-5.1,9.6C487.5,368.2,487.8,370.5,487.7,372.6z
				 M208.8,1275.6c0.3-0.1,0.5-0.2,0.8-0.2c3.8-12.7,8-25.4,11.3-38.2c1.9-7.1,3.6-14.3,2.3-21.9c-0.5-2.6-1.4-4.5-3.9-5.2
				c-2.4-0.7-4.7-0.4-6.9,1.6c-5.2,4.8-7.5,10.9-7.6,17.7c-0.1,7.1,0.3,14.3,0.9,21.3C206.6,1259,207.8,1267.3,208.8,1275.6z
				 M533.1,374.2c-0.1,1-0.3,1.6-0.3,2.2c0.1,10.4-7.2,18.9-17.4,22.7c-5.3,2-9.9,1.4-14.7-0.7c-1.9-0.9-3.6-2.3-5.4-3.5
				c-7.1-4.8-11.6-16.5-9.6-24.9c0.8-3.5,1.6-7.1,2.4-10.7c-3.9,2.4-8.3,3.6-10.3,7.6c3.4,14.5,10.8,26.7,18.5,38.8
				c2.2,3.5,5.3,6.8,10.1,8.7c12.8-9,26.4-17.2,37.9-28C540.3,382.1,536.8,378.2,533.1,374.2z M374.3,1133.3c1-1.4,1.9-2.2,2.3-3.1
				c3-7.8,4.8-16,5.7-24.3c0.8-7.2,1.3-14.5,5.1-20.9c2.8-4.8,6.1-9.3,9.2-13.8c-6.4-0.3-27.6,8.6-31.2,13.3
				c6.1,0.1,7.5,1.6,7.6,7.9c0.3,12.8,0.7,25.5,1,38.3C373.8,1131.4,374,1132,374.3,1133.3z M686.2,778.9c2.2,0.5,3.9,1.4,5.5,1.3
				c3.3-0.2,6.6-0.7,9.7-1.5c21.3-5.3,43-8.2,64.8-10.5c7.5-0.8,14.9-2.3,22.4-3.5c0.1,0,0.2-0.6,0.4-1.5c-1.6-0.2-3.2-0.4-4.7-0.5
				c-1.8-0.1-3.7,0-5.5-0.3c-12.8-1.9-25.5-0.5-38.1,1.8c-15.5,2.9-30.9,6.2-46.4,9.6C691.6,774.4,688.5,774.8,686.2,778.9z
				 M608.1,502.2c1.6-4.5,4.3-8.4,3.6-13.7c-8.1,2.9-14,8.1-18.9,14.4c-3.1,4.1-2.5,6.2,2.3,8.3c5.7,2.5,11.5,4.6,17.3,6.7
				c1.3,0.5,3,0.1,4.1,0.1C617.5,510.3,612.3,506.5,608.1,502.2z M1019.9,821.1c-1.9,0.3-3.3,0.5-4.7,0.7c-5.2,0.7-10.4,1.4-15.6,2
				c-3,0.4-5.3,2.1-5.4,4.9c-0.4,6-0.1,12-0.1,17.1C1002.4,837.7,1011,829.6,1019.9,821.1z M853.1,957.8c1.2,1.1,1.5,1.6,1.9,1.7
				c8.6,4.1,17.3,4.9,26.1,1.1c5.3-2.2,10.5-4.7,15.9-6.9c6.7-2.7,13.2-6,20.7-5.7c0.5,0,1-0.5,1.6-0.9c-4.2-5.3-7.5-5.8-12.8-2.5
				c-5.3,3.3-10.5,6.9-16.2,9.2c-5.4,2.1-11.4,3.2-17.2,3.8C866.8,958.3,860.5,957.8,853.1,957.8z M890.1,429.9
				c-0.1-0.5-0.2-1-0.3-1.4c-5.2,0.4-10.4,0.8-15.7,1.4c-8.2,0.9-16.1,3.1-23,7.9c-8,5.5-16.2,10.7-23.9,16.5
				c-2.6,1.9-6.4,3.7-6.2,8.2c2.9,1.8,4.1-0.8,5.3-2.1c8.7-9.5,18.5-17.4,30.5-22.4c9.7-4,19.4-8.4,30.3-7.7
				C888.2,430.2,889.2,430,890.1,429.9z M516.5,337.4c-6.3,2.8-11.8,6.2-15.9,12.6c4.2,0.2,7.5-0.1,10.7,0.6
				c3.2,0.7,6.2,2.3,9.4,3.6C519.3,348.4,518,343.2,516.5,337.4z M698.2,514.6c-2.1-6.4-5.5-10.1-9.4-11c-0.5,3.7-0.9,7.4-1.4,11.5
				C690.9,514.1,694.6,518.4,698.2,514.6z M831.2,507.2c-0.4-0.1-0.7-0.1-1.1-0.2c-0.8,1.5-1.7,2.9-2.3,4.4
				c-0.6,1.5-0.6,3.3-1.3,4.7c-0.8,1.8-1.5,3.8,0.7,4.7c2,0.8,4.5,2.7,6.6-0.2C832.3,516.3,828.9,512.4,831.2,507.2z M825,755.8
				c-4.3-3.4-13.4-2.3-18.4,2.8C812.7,757.7,818.7,756.8,825,755.8z M864.9,494.8c3.5,0.2,6.9,1.8,10-1.8
				C871.2,492.9,867.9,491.6,864.9,494.8z M825.9,760.1c0.1,0.3,0.2,0.5,0.3,0.8c1.5,0,3,0,4.9,0c-0.6-1.1-0.9-1.8-1.5-2.8
				C828.3,758.8,827.1,759.5,825.9,760.1z M648,492.4c1.4-0.7,2.2-1.1,3.5-1.8c-1.2-0.9-2.1-1.6-3.5-2.6
				C648,489.7,648,490.6,648,492.4z M204.9,1324.5c-0.2,0-0.4,0-0.7,0c0,1.9,0,3.7,0,5.6c0.2,0,0.4,0,0.7,0
				C204.9,1328.2,204.9,1326.4,204.9,1324.5z M205.2,1322.1c0.2,0.1,0.4,0.3,0.7,0.4c0-2,0-3.9,0-5.9c-0.2,0-0.4,0-0.7,0
				C205.2,1318.4,205.2,1320.2,205.2,1322.1z M206.8,1308.9c-0.2,0-0.4,0-0.6,0c0,1.1,0,2.3,0,3.4c0.2,0,0.4,0,0.6,0
				C206.8,1311.1,206.8,1310,206.8,1308.9z M632.9,500.6c0.3,0.1,0.6,0.1,0.8,0.2c0.3-0.9,0.6-1.8,0.9-2.7c-0.4-0.1-0.8-0.2-1.2-0.3
				C633.3,498.7,633.1,499.7,632.9,500.6z M204,1334.6c-0.3,0-0.7-0.1-1-0.1c0,0.7,0.1,1.4,0.1,2.1c0.2,0,0.4,0,0.6,0
				C203.9,1336,203.9,1335.3,204,1334.6z M649.5,425c0.5,0.6,0.8,1,0.9,1c0.4-0.2,0.8-0.5,1.1-0.7C651,425.2,650.4,425.1,649.5,425z
				 M656.6,426.9c0-0.3,0-0.6,0-0.9c-0.7,0.1-1.4,0.2-2.1,0.4c0,0.2,0,0.4,0,0.6C655.2,426.9,655.9,426.9,656.6,426.9z M807.7,526.7
				l-0.4,0l0.2,0.4L807.7,526.7z M963.7,466.4c-0.2,0-0.3,0-0.5,0c0,0.5-0.1,1-0.1,1.4c0.2,0,0.4,0,0.6,0
				C963.7,467.4,963.7,466.9,963.7,466.4z M900.4,934.3c0,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6
				C900.6,934.3,900.5,934.3,900.4,934.3z M553.5,459.7l0.2-0.2l-0.3,0L553.5,459.7z M568.3,431.5l0.2,0.2l0-0.2L568.3,431.5z
				 M897.2,430.5l0.3,0.2l0-0.4L897.2,430.5z M571.5,426.8c0.1-0.1,0.3-0.2,0.3-0.3c0-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.3,0.2-0.3,0.3
				C571.2,426.6,571.4,426.7,571.5,426.8z M644.6,424.3l-0.2,0.2l0.3,0L644.6,424.3z M945.4,403.3c0,0.1-0.2,0.2-0.1,0.3
				c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6C945.6,403.3,945.5,403.3,945.4,403.3z M641.8,371.5c-0.1-0.1-0.2-0.3-0.3-0.3
				c-0.1,0-0.2,0.2-0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3C641.7,371.7,641.8,371.6,641.8,371.5z M646.5,369.7l0.2-0.3l-0.3,0L646.5,369.7
				z M648.7,368.4c-0.1,0-0.2-0.2-0.3-0.1c-0.1,0-0.2,0.2-0.3,0.3c0.2,0.1,0.4,0.1,0.6,0.2C648.7,368.6,648.7,368.5,648.7,368.4z
				 M650.6,367.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.3-0.3,0.4C650.4,367.7,650.5,367.8,650.6,367.8z
				 M921.9,664.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.1,0.2,0.2,0.3,0.4C921.6,664.7,921.8,664.6,921.9,664.5z
				 M864.8,653.5c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.3C864.6,653.8,864.7,653.6,864.8,653.5z
				 M868.3,640.3c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6C868.5,640.3,868.4,640.3,868.3,640.3z
				 M912.3,506.5l0.2,0.2l0-0.3L912.3,506.5z M914.4,503.3c0,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6
				C914.6,503.3,914.5,503.3,914.4,503.3z M863.3,495.3c0,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6
				C863.6,495.3,863.5,495.3,863.3,495.3z M672.6,475.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.3-0.3,0.4
				C672.4,475.7,672.5,475.7,672.6,475.8z M615.8,475.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
				C615.7,475.6,615.7,475.5,615.8,475.4z M725.4,474.3c0,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.1-0.4,0.2-0.6
				C725.6,474.3,725.5,474.3,725.4,474.3z'
            />
            <path
              d='M510.8,386.2c0.5,0.6,1.1,1.2,1.5,1.9c1.1,2.1,0.5,4-1.1,5.4c-1.4,1.3-3.2,1.7-4.9,0.3c-0.9-0.7-2.1-1.2-2.7-2.1
				c-3.4-5.2-7.2-10.3-9.7-15.9c-1.3-3-0.8-7.2-0.1-10.6c0.6-3,4.1-6.1,8.5-6.1c6.2,0,10.8,1.9,14.6,6.7c3.3,4.2,6.6,8.3,7.4,13.7
				c0.2,1.1-0.4,2.3-0.7,3.9c-2.9-1.2-5.1-2.2-8.1-3.4c0.6,1.4,1.1,2.1,1.2,2.9c0.2,1.5,0.7,3.4,0,4.4c-1.1,1.6-2.6,0.2-3.8-0.6
				c-0.5-0.4-1.2-0.5-1.8-0.7c-0.3-0.2-0.6-0.3-1-0.5C510.4,385.6,510.6,385.9,510.8,386.2z'
            />
          </g>
        </g>
      </g>
    </Icon>
  )
}
export default Self
